import React, { useContext, useReducer, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import { Collapse, Dropdown } from "react-bootstrap";
import { MenuList } from "./Menu";
import { PhoneMenu } from "./PhoneMenu";
import { PhoneMenuCard } from "./PhoneMenuCard";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { Navbar, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
};

const SideBar = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobilemenu, setmobilemenu] = useState(PhoneMenu);

  var d = new Date();
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    ChangeIconSidebar,
  } = useContext(ThemeContext);

  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );

  // if (localUser?.cards?.cardKitNumber) {
  //   setmobilemenu(PhoneMenuCard);
  // }

  const [state, setState] = useReducer(reducer, initialState);

  let handleheartBlast = document.querySelector(".heart");
  function heartBlast() {
    return handleheartBlast.classList.toggle("heart-blast");
  }

  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  const handleMenuActive = (status) => {
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  };
  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" });
    }
  };
  // Menu dropdown list End

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (path) => {
    navigate(path);
    handleClose();
  };

  return (
    <>
      {" "}
      <BottomNavigation
        showLabels
        className="d-md-none fixed-bottom p-0 text-black op-1"
        style={{
          flexDirection: "row",
          justifyContent: "space-around",
          height: "60px",
          alignItems: "center",
          borderTop: "0.5px solid",
          zIndex: "100",
        }}
      >
        {mobilemenu
          .filter((data) => data.title !== "KYC")
          .map((data, index) =>
            data.title === "More" ? (
              <div key={index}>
                <BottomNavigationAction
                  label={data.title}
                  icon={data.icon}
                  className="text-black"
                  onClick={handleClick}
                />
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => handleMenuItemClick("/bridge")}>
                    Bridge
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick("/kyc")}>
                    KYC
                  </MenuItem>
                  <MenuItem
                    onClick={() => navigate("https://t.me/BridgeBit_Support")}
                  >
                    Support
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick("/wallets")}>
                    Wallets
                  </MenuItem>
                </Menu>
              </div>
            ) : (
              <BottomNavigationAction
                key={index}
                label={data.title}
                icon={data.icon}
                className="text-black"
                href={data.title === "Support" ? data.to : undefined}
                onClick={
                  data.title !== "Support" ? () => navigate(data.to) : undefined
                }
              />
            )
          )}
      </BottomNavigation>
      <div className="deznav d-none d-md-block">
        <PerfectScrollbar className="deznav-scroll">
          <ul className="metismenu" id="menu">
            {MenuList.filter((data) => data.title !== "More").map(
              (data, index) => {
                let menuClass = data.classsChange;
                if (menuClass === "menu-title") {
                  return (
                    <li className={menuClass + " text-black"} key={index}>
                      {data.title}
                    </li>
                  );
                } else {
                  return (
                    <li
                      className={` ${
                        state.active === data.title ? "mm-active" : ""
                      }`}
                      key={index}
                    >
                      {data.content && data.content.length > 0 ? (
                        <>
                          <Link
                            to={"#"}
                            className="has-arrow"
                            onClick={() => {
                              handleMenuActive(data.title);
                            }}
                          >
                            {data.iconStyle}
                            <span className="nav-text text-black">
                              {data.title}
                            </span>
                          </Link>
                          <Collapse
                            in={state.active === data.title ? true : false}
                          >
                            <ul
                              className={`${
                                menuClass === "mm-collapse" ? "mm-show" : ""
                              }`}
                            >
                              {data.content &&
                                data.content.map(
                                  (submenuData, submenuIndex) => {
                                    return (
                                      <li
                                        key={`${index}-${submenuIndex}`} // generate unique key by combining index and submenuIndex
                                        className={`${
                                          state.activeSubmenu ===
                                          submenuData.title
                                            ? "mm-active"
                                            : ""
                                        }`}
                                      >
                                        {submenuData.content &&
                                        submenuData.content.length > 0 ? (
                                          <>
                                            <Link
                                              to={submenuData.to}
                                              className={
                                                submenuData.hasMenu
                                                  ? "has-arrow"
                                                  : ""
                                              }
                                              onClick={() => {
                                                handleSubmenuActive(
                                                  submenuData.title
                                                );
                                              }}
                                            >
                                              {submenuData.title}
                                            </Link>
                                            <Collapse
                                              in={
                                                state.activeSubmenu ===
                                                submenuData.title
                                                  ? true
                                                  : false
                                              }
                                            >
                                              <ul
                                                className={`${
                                                  menuClass === "mm-collapse"
                                                    ? "mm-show"
                                                    : ""
                                                }`}
                                              >
                                                {submenuData.content &&
                                                  submenuData.content.map(
                                                    (itemData, itemIndex) => {
                                                      return (
                                                        <li
                                                          key={`${index}-${submenuIndex}-${itemIndex}`}
                                                        >
                                                          {" "}
                                                          {/* generate unique key */}
                                                          <Link
                                                            className={`${
                                                              path ===
                                                              itemData.to
                                                                ? "mm-active text-black"
                                                                : "text-black"
                                                            }`}
                                                            to={itemData.to}
                                                          >
                                                            {itemData.title}
                                                          </Link>
                                                        </li>
                                                      );
                                                    }
                                                  )}
                                              </ul>
                                            </Collapse>
                                          </>
                                        ) : (
                                          <Link to={submenuData.to}>
                                            {submenuData.title}
                                          </Link>
                                        )}
                                      </li>
                                    );
                                  }
                                )}
                            </ul>
                          </Collapse>
                        </>
                      ) : (
                        <>
                          <Link to={data.to}>
                            {data.iconStyle}
                            <span className="nav-text">{data.title}</span>
                          </Link>
                        </>
                      )}
                    </li>
                  );
                }
              }
            )}
          </ul>
          {/* <div className="copyright">
          <p>
            <strong>Bridge Bit</strong> © 2024 All Rights Reserved
          </p>
        </div> */}
        </PerfectScrollbar>
      </div>
    </>
  );
};

export default SideBar;
