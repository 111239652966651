import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { Fragment, useState, useContext, useEffect } from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import card1 from "./../../../../images/card/visa.jpg";
import card2 from "./../../../../images/card/card2.png";
import card3 from "./../../../../images/card/card3.png";
import card4 from "./../../../../images/card/card4.png";
import dualdot from "./../../../../images/visa.png";
import logoBlack from "./../../../../images/BB_Logo_Black.svg";
import logoWhite from "./../../../../images/BB_Logo_White.svg";
import { countries } from "../../../../jsx/countries.js";

import CardPremium from "./../../../../images/card/Card_Premium.jpg";
import CardVIP from "./../../../../images/card/Card_VIP.jpg";

import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
// WEB3
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import {
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useAccount,
  useNetwork,
} from "wagmi";

import { ERC20_ABI } from "../../../../../src/contracts/USDT_ABI.js";
import { ReadContract } from "../../../../../src/contracts/ReadContract.js";
import { Row, Col, Card, Accordion } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";

import BuyButton from "../../../../../src/jsx/components/web3/BuyButton.js";
import { TextField, Box, Typography } from "@mui/material";

const contentBlog = [
  {
    images:
      "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/4125a02e-b62d-4119-0619-30e79765f200/public",
    title: "Metal Card",
    balance: (
      <div>
        <del>$999</del> $799
      </div>
    ),
    price: 799,
    cardID: 3,
    accordion: [
      {
        title: "Benefits",
        text: [
          "- Instant Load",
          "- Unique Bridge Bit Metal Design",
          "- Unlock Potential of Rank Gold Director to maximize passive income",
          "- 150.000 USD per Transaction",
          "- 175.000 USD Limit per Month",
        ],
        bg: "primary",
      },
    ],
  },
  {
    images:
      "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/1f866fa3-d81c-45df-e722-cc89eced4600/public",
    title: "Premium Card",
    balance: (
      <div>
        <del>$444</del> $355
      </div>
    ),
    price: 355,
    cardID: 2,
    accordion: [
      {
        title: "Benefits",
        text: [
          "- Unlock the Potential of Passive Income",
          "- Instant Loads",
          "- 3.000 USDT Daily Load Limit",
        ],
        bg: "primary",
      },
    ],
  },
  {
    images:
      "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/1f866fa3-d81c-45df-e722-cc89eced4600/public",
    title: "Standard Card",
    balance: (
      <div>
        <del>$111</del> $88
      </div>
    ),
    price: 88,
    cardID: 1,
    accordion: [
      {
        title: "Benefits",
        text: [
          "- Join the World of Bridge Bit",
          "- Instant Loads",
          "- a500 USDT Daily Top Up Limit",
          "- 2.000 USD Daily ATM",
        ],
        bg: "primary",
      },
    ],
  },
];

const CardCenterOwl = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingShipping, setIsLoadingShipping] = useState(false);
  const [isLoadingAddress, setIsLoadingAddress] = useState(false);
  const [isLoadingTransaction, setisLoadingTransaction] = useState(false);
  const [transactionsconfirmed, settransactionsconfirmed] = useState(false);
  const [shippingsaved, setshippingsaved] = useState(false);
  const [network, setNetwork] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [apialert, setalert] = useState("");
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [createdAddress, setCreatedAddress] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [LoadAmount, setLoadAmount] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [address_in, setaddressin] = useState("");
  const [txn_hash, settxn_hash] = useState("");
  const [confirmations, setconfirmations] = useState(0);
  const [buyError, setbuyError] = useState("");
  const [successMessage, setsuccessMessage] = useState("");
  const [errormessage, seterrormessage] = useState("");
  const [cardID, setCardID] = useState(0);
  const [successBuy, setsuccessBuy] = useState(0);
  const [handlebuy, sethandlebuy] = useState(0);
  const [showIframe, setShowIframe] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("AT");
  const [selectedState, setSelectedState] = useState("");

  const { address, isConnecting, isDisconnected } = useAccount();
  const { chain } = useNetwork();
  const usdtAddress_sol = "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB";
  const usdtAddress_bsc = "0x55d398326f99059ff775485246999027b3197955";
  const usdtAddress_matic = "0xc2132d05d31c914a87c6611c10748aeb04b58e8f";
  const usdtAddress_arbitrum = "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9";
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    address: localUser.shipping
      ? localUser.shipping.address
      : localUser.address,
    city: localUser.shipping ? localUser.shipping.city : localUser.city,
    state: localUser.shipping ? localUser.shipping.state : localUser.state,
    zip: localUser.shipping ? localUser.shipping.zip : localUser.zip,
    country: localUser.shipping
      ? localUser.shipping.country
      : localUser.country,
  });

  const nameRegex = /^[a-zA-Z\s-]+$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phoneRegex = /^[0-9]{9,15}$/;
  const callingCodeRegex = /^[0-9]{3}$/;

  const onlynumbers = /^[0-9]{3,15}$/;
  const stateRegex = /^[a-zA-Z\s-]+$/;

  const validateField = (name, value) => {
    switch (name) {
      case "firstName":
      case "lastName":
      case "country":
        return nameRegex.test(value) && value.length <= 22;

      case "email":
        return emailRegex.test(value) && value.length >= 3;
      case "phone":
      case "cellPhoneNumber":
        return phoneRegex.test(value);
      case "callingCode":
        return callingCodeRegex.test(value) && value.length === 3;

      case "city":
        return nameRegex.test(value) && value.length >= 3;

      case "zip":
        return onlynumbers.test(value) && value.length >= 3;

      case "state":
        return value.length >= 2 && value.length <= 25;
      // Add other field validations here...
      default:
        return true;
    }
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    const mapObject = {
      ä: "ae",
      ö: "oe",
      ü: "ue",
      ß: "ss",
    };

    if (
      name === "firstName" ||
      name === "lastName" ||
      name === "state" ||
      name === "address" ||
      name === "city"
    ) {
      // Apply transformation
      value = value.replace(/ä|ö|ü|ß/gi, (matched) => mapObject[matched]);
    }

    const mapPhone = {
      "+": "00",
    };
    if (name === "phone") {
      value = value.replace(/\+/gi, (matched) => mapPhone[matched]);
    }
    const isValid = validateField(name, value);

    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: isValid ? "" : "Invalid input" });
  };
  const handleCountryChange = (e) => {
    console.log("handleCountryChange", e.target.value);
    const newCountry = e.target.value;
    setSelectedCountry(newCountry);
    setFormData({ ...formData, country: newCountry, state: "" });
  };
  const handlebuyerror = (error) => {
    console.log("Error:", error);
    setbuyError(error);
  };

  const handleButtonClick = () => {
    setShowIframe(!showIframe);
  };

  const handlebuysuccess = async (cardID) => {
    // Make function async
    console.log("Success CardID:", cardID);
    setsuccessMessage("Card Buy successfull");
    await fetchUserData(localUser.firebaseUid);

    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };

  useEffect(() => {
    if (localUser?.cards?.card_type) {
      setsuccessBuy(1);
    }

    if (localUser?.shipping) {
      setshippingsaved(true);
    }

    const script = document.createElement("script");
    script.src =
      "https://changenow.io/embeds/exchange-widget/v2/stepper-connector.js";
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (address) {
      console.log("Address:", address);
    }
  }, [address]);

  const fetchUserData = (firebaseUid) => {
    return fetch(`https://api.bridge-bit.com/api/user/${firebaseUid}`) // added return here
      .then((response) => {
        if (!response.ok) {
          throw new Error("User not found.");
        }
        return response.json();
      })
      .then((userData) => {
        localStorage.setItem("user", JSON.stringify(userData));
        console.log("User data updated:", userData);
      });
  };

  const fetchUserTransactions = async () => {
    try {
      console.log("address in", address_in);
      const response = await fetch(
        `https://api.bridge-bit.com/api/user-transactions/${address_in}`
      );
      const data = await response.json();
      console.log("User transactions:", data);
      settxn_hash(data.txid_in);
      setconfirmations(data.confirmations);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setTransactions(response.data);

      if (data.status == "confirmed" && data.amount >= LoadAmount) {
        setisLoadingTransaction(false);
        settransactionsconfirmed(true);

        axios
          .post("https://api.bridge-bit.com/api/successbuy", {
            user: localUser.id,
            cardID: cardID,
            coin: 1,
            // address: address,
          })
          .then(async (response) => {
            console.log(response);
            setsuccessMessage("Card Buy successfull");
            await fetchUserData(localUser.firebaseUid);

            setTimeout(() => {
              window.location.reload();
            }, 5000);
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (data.status == "confirmed" && data.amount < LoadAmount) {
        setisLoadingTransaction(false);
        settransactionsconfirmed(false);
        seterrormessage(
          "Amount not enough " + data.amount + " / " + LoadAmount
        );
      }
    } catch (error) {
      console.error("Error fetching user transactions:", error);
    }
  };

  useEffect(() => {
    if (address_in !== "" && isLoadingTransaction) {
      const interval = setInterval(() => {
        fetchUserTransactions();
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [address_in]);

  const handlesetNetwork = (target) => {
    console.log("Network:", target);
    setNetwork(target);
    createapiaddress(target);
  };

  const handleLoadBuy = async ({ usdtPrice, cardID }) => {
    console.log("Address:", address);
    console.log("chain:", chain);
    console.log("usdtPrice:", usdtPrice);
    console.log("cardID:", cardID);
    setCardID(cardID);
    if (address) {
    } else {
      setLoadAmount(usdtPrice);
      setShowModal(true);
    }
    // if (!allowance) {
    //   console.log("Allowance:", allowance);
    // }
  };

  const handleCloseModal = () => {
    console.log("Close MOdal");
    setaddressin("");
    setCreatedAddress("");
    setNetwork("");
    setisLoadingTransaction(false);
    settransactionsconfirmed(false);
    setShowModal(false);
  };

  const createapiaddress = async (network) => {
    var blockchain = network;
    setIsLoadingAddress(true);
    try {
      var ticker;
      let address;
      if (blockchain === "matic") {
        ticker = "polygon/usdt";
        address =
          "0.25" +
          "@0x0bC0A66E75FF4FaE3e449912d0b98129C81a6803|" +
          "0.1" +
          "@0x593f27504F2bE394C648F2216319Cb7fC9cf37bE| " +
          "0.65" +
          "@0x884381f19A5B2BFB604AEd8a4251Be2f7f76591f";
      } else if (blockchain === "bep20") {
        ticker = "bep20/usdt";
        address =
          "0.25" +
          "@0x0bC0A66E75FF4FaE3e449912d0b98129C81a6803|" +
          "0.1" +
          "@0x593f27504F2bE394C648F2216319Cb7fC9cf37bE| " +
          "0.65" +
          "@0x884381f19A5B2BFB604AEd8a4251Be2f7f76591f";
      } else if (blockchain === "trc20") {
        ticker = "trc20/usdt";
        address =
          "0.25" +
          "@TBp9LQvsEgy7H5cFQN3f1zPtMRk7ZPmENC|" +
          "0.1" +
          "@TXzaQQXPK7CuyA9s1kLbxW8WJNPVw5dNWG| " +
          "0.65" +
          "@TA69wZdotYePP96R9CTbb8RHSZjbgrSaBH";
      } else if (blockchain === "btc") {
        ticker = "btc";
        address = "1Gm7aeCHGfCapRbxfPuK7RQiCXNNkyFEwo";
      }

      const query = new URLSearchParams({
        callback: `http://api.bridge-bit.com/api/cryptapi-callback?user_id=${
          localUser.id
        }&time=${Math.floor(Date.now() / 1000)}`, // Replace with actual callback URL
        address: address,
      }).toString();

      const response = await fetch(
        `https://api.cryptapi.io/${ticker}/create/?${query}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Address created:", data);

      console.log("Status:", data.status);

      if (data.status === "error") {
        setalert(data.error);
      } else if (data.status === "success") {
        setaddressin(data.address_in);
        setCreatedAddress(data.address_in);
        fetchQrCode(blockchain, data.address_in);
        setisLoadingTransaction(true);
      }

      setIsLoadingAddress(false);
    } catch (error) {
      console.error("Error creating new address:", error);
      setIsLoadingAddress(false);
    }
  };

  const fetchQrCode = async (blockchain, address) => {
    try {
      var value = LoadAmount;
      const query = new URLSearchParams({
        address: address,
        value: value,
        size: "512",
      }).toString();

      var ticker;
      if (blockchain === "matic") {
        ticker = "polygon/usdt";
      } else if (blockchain === "bep20") {
        ticker = "bep20/usdt";
      } else if (blockchain === "trc20") {
        ticker = "trc20/usdt";
      } else if (blockchain === "btc") {
        ticker = "btc";
      }

      const response = await fetch(
        `https://api.cryptapi.io/${ticker}/qrcode/?${query}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("QR Code data:", data);

      if (data.status === "error") {
        alert(data.error);
      } else if (data.qr_code) {
        setQrCode(data.qr_code);
      }
    } catch (error) {
      console.error("Error fetching QR code:", error);
    }
  };

  const settings = {
    dots: true,
    infinite: false,
    arrows: true,

    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "270px",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "100px",
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleSubmit = async (e) => {
    console.log(formData);
    e.preventDefault();
    setIsLoadingShipping(true);

    try {
      const cardHolderId = localUser.cardHolderId;

      const shippingData = {
        userId: localUser.id,
        card_holder_id: cardHolderId,
        ...formData,
      };
      console.log("shippingData", shippingData);

      const response = await fetch(
        "https://api.bridge-bit.com/api/update_shipping",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(shippingData),
        }
      );
      const data = await response.json();
      if (data.success) {
        console.log("Shipping information updated successfully", data.message);
        setshippingsaved(true);
        setIsLoadingShipping(false);
      } else {
        console.error("Failed to update shipping information", data.error);
        setIsLoadingShipping(false);
      }
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  return (
    <>
      {successBuy === 1 ? (
        <>
          <div className="items p-3">
            <div
              className="card-bx mb-0 d-flex"
              style={{
                borderRadius: "10px",
                backgroundImage: `url(${
                  contentBlog.find(
                    (blog) => blog.cardID === localUser?.cards?.card_type
                  )?.images
                }
				)`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                minHeight: "230px",
              }}
            >
              <div className="card-info text-white">
                <h3
                  className="p-3 mb-1 mt-1 text-white mb-sm-5 mb-3"
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  {contentBlog.find(
                    (blog) => blog.cardID === localUser?.cards?.card_type
                  )?.title === "Metal Card" ? (
                    <></>
                  ) : (
                    contentBlog.find(
                      (blog) => blog.cardID === localUser?.cards?.card_type
                    )?.title
                  )}
                </h3>

                {/* <div className="d-flex align-items-center justify-content-between text-center mb-sm-5 mb-3"> */}

                <div
                  className="d-flex "
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="me-5">
                    <p className="fs-14 mb-1 op6"></p>
                  </div>
                  <div>
                    <p className="fs-14 mb-1 op6"></p>
                  </div>
                </div>
              </div>
            </div>{" "}
            <Accordion
              className="accordion accordion-primary-solid mt-3 p-3 show"
              defaultActiveKey="1"
              style={{ maxWidth: "350px" }}
            >
              {contentBlog
                .find((item) => item.cardID === localUser?.cards?.card_type)
                ?.accordion.map((d, i) => (
                  <Accordion.Item eventKey={i.toString()} key={i}>
                    <Accordion.Header className="accordion-header fs-20">
                      {" "}
                      {d.title}
                    </Accordion.Header>
                    <Accordion.Collapse
                      eventKey={1}
                      className="accordion__body  fs-16 text-black"
                    >
                      <div className="accordion-body">
                        {" "}
                        {d.text.map((line, idx) => (
                          <span key={idx}>
                            {line} <br />
                          </span>
                        ))}
                      </div>
                    </Accordion.Collapse>
                  </Accordion.Item>
                ))}
            </Accordion>
            <div className="card">
              <div className="card-body">
                <h3 className="text-black text-center mb-3">
                  Shipping Address Information
                </h3>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6 mb-2">
                      <div className="form-group mb-3">
                        <label className="text-label  text-black">
                          Address Line*
                        </label>
                        <input
                          type="text"
                          name="address"
                          className="form-control text-black"
                          onChange={handleChange}
                          value={formData.address}
                          disabled={shippingsaved}
                          style={shippingsaved ? { opacity: 0.6 } : {}}
                          required
                        />
                        {errors.address && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors.address}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <div className="form-group mb-3">
                        <label className="text-label  text-black">City*</label>
                        <input
                          type="text"
                          name="city"
                          className="form-control text-black"
                          onChange={handleChange}
                          value={formData.city}
                          disabled={shippingsaved}
                          style={shippingsaved ? { opacity: 0.6 } : {}}
                          required
                        />
                        {errors.city && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors.city}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6 mb-2">
                      <div className="form-group mb-3">
                        <label className="text-label  text-black">
                          Country*
                        </label>
                        <select
                          name="country"
                          value={selectedCountry}
                          onChange={handleCountryChange}
                          disabled={shippingsaved}
                          style={shippingsaved ? { opacity: 0.6 } : {}}
                          className="form-control text-black"
                        >
                          {countries.map((country, index) => (
                            <option key={index} value={country.isoCode}>
                              {country.name}
                            </option>
                          ))}
                        </select>
                        {errors.country && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors.country}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <div className="form-group mb-3">
                        <label className="text-label  text-black">State*</label>
                        <input
                          name="state"
                          className="form-control text-black"
                          type="text"
                          value={formData.state}
                          onChange={handleChange}
                          disabled={shippingsaved}
                          style={shippingsaved ? { opacity: 0.6 } : {}}
                          required
                        />
                        {errors.state && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors.state}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <div className="form-group mb-3">
                        <label className="text-label  text-black">
                          Postal Code*
                        </label>
                        <input
                          type="text"
                          name="zip"
                          className="form-control text-black"
                          onChange={handleChange}
                          value={formData.zip}
                          disabled={shippingsaved}
                          style={shippingsaved ? { opacity: 0.6 } : {}}
                          required
                        />
                        {errors.zip && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors.zip} (only numbers allowed)
                          </div>
                        )}
                      </div>
                    </div>

                    {shippingsaved && (
                      <div className="p-3">
                        <Alert variant="success text-center">
                          Shipping information saved successfully.
                        </Alert>
                      </div>
                    )}
                    {!shippingsaved && (
                      <div
                        className="col-lg-12 "
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <button
                          type="submit"
                          className="btn btn-primary "
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            minWidth: "100px",
                          }}
                        >
                          {isLoadingShipping ? (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-2"
                              />
                            </>
                          ) : (
                            <div>Submit</div>
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <h3 className="text-black text-center">PRESALE OFFER</h3>

          {successMessage && (
            <div className="p-3">
              <Alert variant="success text-center">Card Buy successfull</Alert>
            </div>
          )}
          <Slider className="cards  mb-sm-5 mb-3" {...settings}>
            {contentBlog.map((data, index) => (
              <div className="items p-3">
                <h3
                  className="mt-3 text-black"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {data.title}
                </h3>
                <div
                  className="card-bx mb-0 d-flex"
                  style={{
                    borderRadius: "10px",
                    backgroundImage: `url(${data.images})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    minHeight: "230px",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className={`card-info text-white ${
                      data.title === "Metals Card" ? "mt-5" : ""
                    }`}
                  >
                    {/* <div className="d-flex align-items-center justify-content-between text-center mb-sm-5 mb-3"> */}

                    <div className="d-flex align-items-center justify-content-center text-center mb-sm-5 mt-sm-4 mb-3 mt-3">
                      <h2 className="fs-36 text-white mb-sm-2 mb-1">
                        {data.balance}
                      </h2>
                    </div>
                    <div
                      className="d-flex "
                      style={{ justifyContent: "space-between" }}
                    >
                      <div className="me-5">
                        <p className="fs-14 mb-1 op6"></p>
                      </div>
                      <div>
                        <p className="fs-14 mb-1 op6"></p>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                {buyError && (
                  <div className="p-3">
                    <Alert variant="danger">{buyError}</Alert>
                  </div>
                )}
                {address ? (
                  <>
                    <BuyButton
                      Price={data.price}
                      cardID={data.cardID}
                      onError={handlebuyerror}
                      onSuccessBuy={handlebuysuccess}
                      //   onStatusChange={handleStatusChange}
                    />
                    {/* <p className="text-center mt-3">TEST :</p>
                    <BuyButton
                      Price={0.1}
                      cardID={data.cardID}
                      onError={handlebuyerror}
                      onSuccessBuy={handlebuysuccess}
                      //   onStatusChange={handleStatusChange}
                    /> */}
                  </>
                ) : (
                  <>
                    <button
                      type="submit"
                      className="btn btn-primary mt-3"
                      onClick={() =>
                        handleLoadBuy({
                          //   usdtPrice: 1,
                          usdtPrice: data.price,
                          cardID: data.cardID,
                        })
                      }
                      disabled={localUser?.isKycAccepted === "false"}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minWidth: "100px",
                        margin: "0px auto",
                      }}
                    >
                      {isLoading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                          />
                        </>
                      ) : (
                        <div>
                          <>
                            {localUser?.isKycAccepted === "false"
                              ? "KYC required"
                              : "BUY"}
                          </>
                        </div>
                      )}
                    </button>
                  </>
                )}
                <Accordion
                  className="accordion accordion-primary-solid mt-3 p-3"
                  defaultActiveKey="0"
                  style={{ maxWidth: "350px", margin: "0px auto" }}
                >
                  {data.accordion.map((d, i) => (
                    <Accordion.Item eventKey={1}>
                      <Accordion.Header className="accordion-header fs-20">
                        {" "}
                        {d.title}
                      </Accordion.Header>
                      <Accordion.Collapse
                        eventKey={1}
                        className="accordion__body  fs-16 text-black"
                      >
                        <div className="accordion-body">
                          {" "}
                          {d.text.map((line, idx) => (
                            <span key={idx}>
                              {line} <br />
                            </span>
                          ))}
                        </div>
                      </Accordion.Collapse>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            ))}
          </Slider>
        </>
      )}
      {/* NEW row and show address and qr code */}
      {/* {createdAddress && ( */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Select Network</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-md-center p-3">
            <div className="col-lg-12">
              <div
                className="row d-flex"
                style={{ flexDirection: "column", alignItems: "center" }}
              >
                <div
                  className="col-lg-6 d-flex"
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <label className="text-black font-w600 ">
                    Select Blockchain
                  </label>
                  <div
                    className="row"
                    style={{
                      display: "flex",
                      flexDirection: "row", // Change from 'column' to 'row'
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      value="bep20"
                      onClick={(e) => handlesetNetwork("bep20")}
                      className="btn btn-primary mt-3 m-1"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      disabled={network && network === "bep20"}
                    >
                      <img
                        src="https://api.cryptapi.io/media/token_logos/bnb_logo_otj5BsU.png"
                        alt="network"
                        style={{ marginRight: "10px", width: "25px" }}
                      />
                      USDT BEP20
                    </button>

                    {/* <button
                      value="trc20"
                      onClick={(e) => handlesetNetwork("trc20")}
                      className="btn btn-primary mt-3 m-1"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      disabled={network && network !== "trc20"}
                    >
                      <img
                        src="https://cdn.bitkeep.vip/u_b_fdfe0be0-c215-11ed-bb06-6b42bb500220.png"
                        alt="network"
                        style={{ marginRight: "10px", width: "25px" }}
                      />
                      TRC20
                    </button> */}

                    <button
                      value="matic"
                      onClick={(e) => handlesetNetwork("matic")}
                      className="btn btn-primary mt-3 m-1"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      disabled={network && network === "matic"}
                    >
                      <img
                        src="https://api.cryptapi.io/media/token_logos/polygon_logo_ZZ69slV.png"
                        alt="network"
                        style={{ marginRight: "10px", width: "25px" }}
                      />
                      USDT MATIC
                    </button>

                    {/* <button
                      value="btc"
                      onClick={(e) => handlesetNetwork("btc")}
                      className="btn btn-primary mt-3 m-1"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      disabled={network && network !== "btc"}
                    >
                      <img
                        src="https://api.cryptapi.io/media/token_logos/btc.png"
                        alt="network"
                        style={{ marginRight: "10px", width: "25px" }}
                      />
                      BTC
                    </button> */}

                    <button
                      className="btn btn-primary mt-3 m-1"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={handleButtonClick}
                    >
                      <i className="fa-solid fa-rotate m-1"></i>
                      {showIframe ? "Close" : "Bridge"}
                    </button>
                  </div>
                </div>
                <div className="col-12 text-center mt-4">
                  {showIframe ? (
                    <iframe
                      className="mt-3"
                      id="iframe-widget"
                      src="https://changenow.io/embeds/exchange-widget/v2/widget.html?FAQ=false&amount=5000&amountFiat&backgroundColor=FFFFFF&darkMode=false&from=usdttrc20&horizontal=false&isFiat=false&lang=en-US&link_id=26d728182950fd&locales=true&logo=false&primaryColor=ec6631&to=usdtbsc&toTheMoon=false"
                      style={{
                        height: "356px",
                        width: "-webkit-fill-available",
                        border: "none",
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div
                className="row d-flex mt-3"
                style={{ flexDirection: "column", alignItems: "center" }}
              >
                {apialert && <Alert variant="danger">{apialert}</Alert>}
              </div>

              {createdAddress && (
                <div
                  className="row d-flex mt-3"
                  style={{ flexDirection: "column", alignItems: "center" }}
                >
                  <div
                    className="col-lg-12 d-flex"
                    style={{
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <label className="text-black font-w600 ">Address</label>
                    <div className="input-group mb-3 mt-3">
                      <input
                        type="text"
                        className="form-control text-black"
                        placeholder="0x38861AF3f588c36560931d50aa45b37c7aD8D8C4"
                        value={createdAddress}
                        readOnly
                      />

                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                          navigator.clipboard
                            .writeText(createdAddress)
                            .then(() => alert("Address copied to clipboard!"))
                            .catch((error) =>
                              console.error("Could not copy text: ", error)
                            );
                        }}
                      >
                        COPY
                      </button>
                    </div>
                    <label className="text-black font-w600 ">Amount</label>
                    <div
                      className="input-group mb-3 mt-3"
                      style={{ maxWidth: "200px" }}
                    >
                      <input
                        type="number"
                        className="form-control text-black"
                        placeholder="0"
                        value={LoadAmount}
                        readOnly
                      />

                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                          navigator.clipboard
                            .writeText(LoadAmount)
                            .then(() => alert("Amount copied to clipboard!"))
                            .catch((error) =>
                              console.error("Could not copy text: ", error)
                            );
                        }}
                      >
                        COPY
                      </button>
                    </div>
                    {qrCode && (
                      <div
                        className="d-flex mb-3"
                        style={{ justifyContent: "center" }}
                      >
                        <img
                          src={`data:image/png;base64,${qrCode}`}
                          alt="QR Code"
                          style={{ maxWidth: "220px" }}
                        />
                      </div>
                    )}

                    {isLoadingTransaction ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                        {txn_hash ? (
                          <>
                            <label className="text-black font-w600 mt-3">
                              Transaction Hash
                            </label>
                            <label className="text-black font-w600 mt-3">
                              {txn_hash.substring(0, 15)}...
                            </label>
                            <label className="text-black font-w600 mt-3">
                              Confirmations : {confirmations}
                            </label>
                          </>
                        ) : (
                          <>
                            <label className="text-black font-w600 mt-3">
                              Waiting for Payment
                            </label>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <label className="text-black font-w600 mt-3">
                          Transaction Hash
                        </label>
                        <label className="text-black font-w600">
                          {txn_hash.substring(0, 25)}...
                        </label>
                        <label className="text-black font-w600 mt-3">
                          Confirmations : {confirmations}
                        </label>
                        {transactionsconfirmed && (
                          <div className="alert alert-success" role="alert">
                            Transaction Confirmed. Your presale card has been
                            purchased successfully.
                          </div>
                        )}
                        {errormessage && (
                          <div className="alert alert-danger" role="alert">
                            {errormessage}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-lg-6"></div>{" "}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {!isLoadingTransaction && (
            <Button variant="primary" onClick={handleCloseModal}>
              Close
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      {/* )} */}
    </>
  );
};

export default CardCenterOwl;
