import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {
  getAuth,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "firebase/auth";
import logo from "../../images/BB_Logo_White.svg";

function PasswordResetPage() {
  let navigate = useNavigate();
  const location = useLocation();
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("info");

  // Extract the code from the URL.
  const queryParams = new URLSearchParams(location.search);
  const oobCode = queryParams.get("oobCode");

  const handlePasswordReset = () => {
    if (!newPassword) {
      setMessage("Please enter a new password.");
      setSeverity("error");
      return;
    }

    const auth = getAuth();
    confirmPasswordReset(auth, oobCode, newPassword)
      .then(() => {
        setMessage(
          "Your password has been reset successfully. You can now log in with your new password."
        );
        setSeverity("success");
        // Redirect or give a link to navigate to the login page after a short delay
        setTimeout(() => navigate("/login"), 3000); // Redirect to login after 3 seconds
      })
      .catch((error) => {
        setMessage(
          "Failed to reset password. The link may be expired or invalid."
        );
        setSeverity("error");
        console.error("Error resetting password:", error);
      });
  };

  return (
    <div
      className="authincation h-100 p-meddle"
      style={{
        background: "white",
        WebkitFontSmoothing: "antialiased",
        fontFamily: "var(--wui-font-family)",
        fontStyle: "normal",
        textRendering: "optimizespeed",
        fontWeight: "bold",
        fontSize: "1rem",
        padding: "0.5rem 0.75rem",
      }}
    >
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div
              className="authincation-content"
              style={{ background: "black", borderRadius: "20px" }}
            >
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form" style={{ padding: "20px 20px" }}>
                    <div className="mb-4 text-center">
                      <img
                        src={logo}
                        alt=""
                        className="me-2"
                        style={{ width: "150px" }}
                      />
                    </div>
                    <Typography
                      component="h1"
                      variant="h5"
                      align="center"
                      gutterBottom
                      className="text-white"
                    >
                      Password Reset
                    </Typography>
                    {message && (
                      <Alert
                        severity={severity}
                        style={{ width: "100%", marginBottom: "20px" }}
                      >
                        {message}
                      </Alert>
                    )}

                    <div className="form-group">
                      <label className="mb-2 ">
                        <strong className="text-white">Password</strong>
                      </label>
                      <input
                        type="password"
                        className="form-control text-black"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className="btn bg-white text-black btn-block"
                        style={{
                          borderRadius: "45px",
                          maxWidth: "fit-content",
                          WebkitFontSmoothing: "antialiased",
                          fontFamily: "var(--wui-font-family)",
                          fontStyle: "normal",
                          textRendering: "optimizespeed",
                          fontWeight: "bold",
                          fontSize: "1rem",
                          padding: "0.5rem 0.75rem",
                          height: "fit-content",
                        }}
                        onClick={handlePasswordReset}
                      >
                        Reset Password
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordResetPage;
