import React, { Fragment, useState, useContext, useEffect } from "react";
import { Dropdown, Button, Modal, Alert, Table } from "react-bootstrap";
import profile from "../../images/profile/profile.png";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Donut2 from "../components/Mophy/MyWallet/Donut2";
import PerfectScrollbar from "react-perfect-scrollbar";

import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
// WEB3
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import {
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useAccount,
  useNetwork,
} from "wagmi";
import { Row, Col, Card, Accordion } from "react-bootstrap";
import QRCode from "react-qr-code";

const Wallets = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAddress, setIsLoadingAddress] = useState(false);
  const [isLoadingTransaction, setisLoadingTransaction] = useState(false);
  const [userWallets, setuserWallets] = useState({});
  const [userWalletsINT, setuserWalletsINT] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [showSaveAlert, setShowSaveAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [lastSavedWallet, setLastSavedWallet] = useState(null);
  const { address, isConnecting, isDisconnected } = useAccount();

  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );

  const [secret, setSecret] = useState("");
  const [token, setToken] = useState("");
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [is2FAEnabled, setIs2FAEnabled] = useState(false);

  useEffect(() => {
    const generate2FASecret = async () => {
      try {
        const response = await axios.get(
          `https://api.bridge-bit.com/api/generate2FASecret/${localUser.id}`
        );
        setSecret(response.data.secret);
        const url = `otpauth://totp/BridgeBit:${localUser.email}?secret=${response.data.secret}&issuer=BridgeBit`;
        setQrCodeUrl(url);
      } catch (error) {
        console.error("Error generating 2FA secret:", error);
      }
    };

    generate2FASecret();
  }, [localUser.id, localUser.email]);

  const handleEnable2FA = async () => {
    try {
      const response = await axios.post(
        `https://api.bridge-bit.com/api/enable2FA`,
        {
          userId: localUser.id,
          token: token,
        }
      );
      if (response.data.message === "2FA is enabled successfully.") {
        setIs2FAEnabled(true);
        // Optionally, show success message or update UI
      }
    } catch (error) {
      console.error("Error enabling 2FA:", error);
      // Optionally, show error message
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      console.log("trying fetch wallets");
      try {
        const response = await fetch(
          `https://api.bridge-bit.com/api/get_userwallets/${localUser.id}`
        );
        if (!response.ok) {
          throw new Error("User not found.");
        }
        const userData = await response.json();
        console.log("userData", userData);
        if (userData.message === "UserWallets not found") {
          setuserWallets({
            bsc_usdt: "",
            trc20_usdt: "",
            solana_usdt: "",
            arbitrum_usdt: "",
            matic_usdt: "",
            btc: "",
          });
          setuserWalletsINT({
            bsc_usdt: "",
            trc20_usdt: "",
            solana_usdt: "",
            arbitrum_usdt: "",
            matic_usdt: "",
            btc: "",
          });
        } else {
          setuserWallets(userData.user_wallet);
          setuserWalletsINT(userData.user_wallet);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();
  }, [localUser]);

  const cardBlog = [
    {
      name: "USDT BSC",
      walletType: "bsc_usdt",
      image: "https://api.cryptapi.io/media/token_logos/bnb_logo_otj5BsU.png",
      numb: "2256",
      wallet: "",
    },
    // {
    //   name: "USDT TRC20 ",
    //   walletType: "trc20_usdt",
    //   image:
    //     "https://cdn.bitkeep.vip/u_b_fdfe0be0-c215-11ed-bb06-6b42bb500220.png",
    //   numb: "6551",
    //   wallet: "",
    // },
    // {
    //   name: "USDT SOLANA",
    //   walletType: "sol_usdt",
    //   image: "https://cryptologos.cc/logos/solana-sol-logo.png",
    //   numb: "6783",
    //   wallet: "",
    // },
    // {
    //   name: "USDT ARBITRUM",
    //   walletType: "arbitrum_usdt",
    //   image: "https://cryptologos.cc/logos/arbitrum-arb-logo.png",
    //   numb: "8843",
    //   wallet: "",
    // },
    {
      name: "USDT MATIC",
      walletType: "matic_usdt",
      image: "https://cryptologos.cc/logos/polygon-matic-logo.png",
      numb: "8843",
      wallet: "0x00000000000000000000000000000000",
    },
    // {
    //   name: "BTC",
    //   walletType: "btc",
    //   type: "https://cryptologos.cc/logos/bitcoin-btc-logo.png",
    //   numb: "8843",
    //   wallet: "0x00000000000000000000000000000000",
    // },
  ];

  const handleWalletAddressChange = (walletType, value) => {
    setuserWallets({ ...userWallets, [walletType]: value });
  };

  const handleSaveWeb3 = async () => {
    console.error("handleSaveWeb3:", address);
    try {
      const response = await axios.post(
        "https://api.bridge-bit.com/api/save-wallet",
        {
          userId: localUser.id, // Replace with actual user ID
          walletType: "web3",
          walletAddress: address,
        }
      );
      console.log(response.data);
      setShowSaveAlert(true);
      setTimeout(() => setShowSaveAlert(false), 3000); // Hide the alert after 3 seconds
    } catch (error) {
      console.error("Error saving wallet:", error);
      setShowErrorAlert("Wallet already in use");
    }
  };

  useEffect(() => {
    if (address) {
      if (!localUser.web3_wallet) {
        handleSaveWeb3();
      }
    }
  }, [address]);

  const handleSaveWallet = async (walletType) => {
    setIsLoading(true);
    const walletAddress = userWallets[walletType];
    setLastSavedWallet(walletType);

    console.log(`Saving ${walletType}: ${walletAddress}`);

    try {
      const response = await axios.post(
        "https://api.bridge-bit.com/api/save-wallet",
        {
          userId: localUser.id, // Replace with actual user ID
          walletType: walletType,
          walletAddress: walletAddress,
        }
      );
      console.log(response.data);
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000); // Hide the alert after 3 seconds
      setIsLoading(false);
    } catch (error) {
      console.error("Error saving wallet:", error);
      // Handle error (e.g., show an error message)
    }
  };

  return (
    <Fragment>
      <div className="form-head mb-4">
        <h2 className="text-black font-w600 mb-0">Wallets</h2>
      </div>
      <div className="row">
        <div className="col-xl-9">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                {!localUser.web3_wallet && (
                  <div className="card-header d-sm-flex d-block border-0 pb-0">
                    <div>
                      <div className="mb-3">
                        <h4 className="fs-20 text-black">
                          Set Your SignIn Wallet
                        </h4>

                        <div
                          className="form-group mb-3 d-flex"
                          style={{ alignItems: "center" }}
                        >
                          <w3m-button
                            balance="hide"
                            className="m-2"
                            label="Connect"
                          />
                          <input
                            type="text"
                            name="wallet"
                            className="form-control text-black m-2"
                            style={{ width: "390px" }}
                            value={address}
                            disabled={address}
                          />{" "}
                        </div>
                        {showSaveAlert && (
                          <Alert variant="success" className="mt-3 text-center">
                            Wallet saved successfully!
                          </Alert>
                        )}
                        {showErrorAlert && (
                          <Alert variant="danger" className="mt-3 text-center">
                            {showErrorAlert}
                          </Alert>
                        )}
                      </div>
                      <h4 className="fs-20 text-black">Set Your Wallets</h4>
                      <span className="fs-12">
                        Your wallet address can be entered only once and will
                        only be changable via support.
                      </span>
                    </div>
                  </div>
                )}
                <div className="card-body pb-0">
                  {cardBlog.map((item, index) => (
                    <>
                      <div
                        className="d-flex mb-3 border-bottom flex-wrap align-items-center"
                        style={{ justifyContent: "space-evenly" }}
                      >
                        <div className="d-flex pb-3 align-items-center">
                          <img
                            src={item.image}
                            alt=""
                            style={{ marginRight: "10px", width: "45px" }}
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-label text-black">
                            {item.name}
                          </label>{" "}
                          <input
                            type="text"
                            name="wallet"
                            className="form-control text-black"
                            style={{
                              width: "340px",
                              opacity: userWallets[item.walletType] ? 0.6 : 1,
                            }}
                            onChange={(e) =>
                              handleWalletAddressChange(
                                item.walletType,
                                e.target.value
                              )
                            }
                            value={userWallets[item.walletType]}
                            disabled={userWallets[item.walletType]}
                            required
                          />
                        </div>
                        {!userWalletsINT[item.walletType] && (
                          <button
                            type="submit"
                            className="btn btn-primary "
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              minWidth: "100px",
                            }}
                            onClick={() => handleSaveWallet(item.walletType)}
                            disabled={userWalletsINT[item.walletType]}
                          >
                            {isLoading &&
                            lastSavedWallet === item.walletType ? (
                              <>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  className="me-2"
                                />
                              </>
                            ) : (
                              <div>SAVE</div>
                            )}
                          </button>
                        )}
                      </div>
                      {showAlert && lastSavedWallet === item.walletType && (
                        <div className="row">
                          <div className="col-lg-12">
                            <Alert variant="success" className="mt-3">
                              Wallet saved successfully!
                            </Alert>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header d-sm-flex d-block border-0 pb-0">
                  <div>
                    <h4 className="fs-20 text-black">
                      Two Factor Authentication
                    </h4>
                    <span className="fs-12">
                      Two-factor authentication (2FA) adds an extra layer of
                      security to your account.
                    </span>
                  </div>
                </div>
                <div className="card-body pb-0">
                  <div
                    className="d-flex mb-3 border-bottom flex-wrap align-items-center align-content-flex-start flex-direction-column"
                    style={{
                      flexDirection: "column",
                      alignContent: "center",
                    }}
                  >
                    <div className="form-group mb-3">
                      <label className="text-label text-black">
                        Secret Key
                      </label>{" "}
                      <input
                        type="text"
                        name="secret"
                        className="form-control text-black"
                        style={{ width: "340px" }}
                        value={secret}
                        disabled
                      />
                    </div>
                    <div
                      className="form-group mb-3 d-flex"
                      style={{ flexDirection: "column" }}
                    >
                      <label className="text-label text-black">QR Code</label>{" "}
                      <QRCode value={qrCodeUrl} size={100} />
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-label text-black"> Token</label>{" "}
                      <input
                        type="text"
                        name="token"
                        className="form-control text-black"
                        style={{ width: "340px" }}
                        value={token}
                        onChange={(e) => setToken(e.target.value)}
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary "
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minWidth: "100px",
                      }}
                      onClick={handleEnable2FA}
                      disabled={is2FAEnabled}
                    >
                      {is2FAEnabled ? "Enabled" : "Enable"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>*/}
        </div>
      </div>
    </Fragment>
  );
};

export default Wallets;
