import React, { Fragment, useState, useContext, useEffect } from "react";
import { Dropdown, Button, Modal, Alert, Table } from "react-bootstrap";
import profile from "../../images/profile/profile.png";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Donut2 from "../components/Mophy/MyWallet/Donut2";
import PerfectScrollbar from "react-perfect-scrollbar";

import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
// WEB3
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import {
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useAccount,
  useNetwork,
} from "wagmi";
import { Row, Col, Card, Accordion } from "react-bootstrap";
import QRCode from "react-qr-code";

const Bridge = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAddress, setIsLoadingAddress] = useState(false);
  const [isLoadingTransaction, setisLoadingTransaction] = useState(false);
  const [userWallets, setuserWallets] = useState({});
  const [userWalletsINT, setuserWalletsINT] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [showSaveAlert, setShowSaveAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [lastSavedWallet, setLastSavedWallet] = useState(null);
  const { address, isConnecting, isDisconnected } = useAccount();

  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://changenow.io/embeds/exchange-widget/v2/stepper-connector.js";
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Fragment>
      <div className="form-head mb-4">
        <h2 className="text-black font-w600 mb-0">Bridge </h2>
      </div>
      <div className="row">
        <div className="col-xl-9">
          <div className="row">
            {" "}
            <iframe
              className="mt-3"
              id="iframe-widget"
              src="https://changenow.io/embeds/exchange-widget/v2/widget.html?FAQ=false&amount=5000&amountFiat&backgroundColor=FFFFFF&darkMode=false&from=usdttrc20&horizontal=false&isFiat=false&lang=en-US&link_id=26d728182950fd&locales=true&logo=false&primaryColor=ec6631&to=usdtbsc&toTheMoon=false"
              style={{
                height: "356px",
                width: "-webkit-fill-available",
                border: "none",
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Bridge;
