import React, { Fragment, useState, useContext, useEffect } from "react";
import CardCenterOwl from "../components/Mophy/CardsCenter/CardCenterOwl";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";

const Presale = () => {
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );

  return (
    <Fragment>
      <div className="form-head mb-4">
        {localUser?.cards?.card_type ? (
          <h2 className="text-black font-w600 mb-0">Your Card</h2>
        ) : (
          <h2 className="text-black font-w600 mb-0">Presale</h2>
        )}
      </div>
      <div className="row">
        <div className="col-lg-12">
          <CardCenterOwl />
        </div>
      </div>
    </Fragment>
  );
};

export default Presale;
