import React, { Fragment, useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Modal } from "react-bootstrap";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import TransferOwl from "./../Mophy/Dashboard/TransferOwl";
import TransferBarGraph from "./../Mophy/Dashboard/TransferBarGraph";
import HomeTab from "./../Mophy/Dashboard/HomeTab";
import Donut from "./../Mophy/Dashboard/Donut";
import Wizard from "../Forms/Wizard/Wizard";
import Spinner from "react-bootstrap/Spinner";
import { Row, Card, Col, Button, Nav, Alert } from "react-bootstrap";
import { Stepper, Step } from "react-form-stepper";

import StepOne from "./steps/StepOne";
import StepTwo from "./steps/StepTwo";

//Images
import card1 from "./../../../images/card/card.png";
import dual from "./../../../images/dual-dot.png";
import profile from "./../../../images/user_logo.png";
import visa from "./../../../images/visa_black.PNG";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

//WEB3
import {
  useContractRead,
  useContractWrite,
  useAccount,
  useNetwork,
  useWaitForTransaction,
} from "wagmi";
import { usdt_abi } from "../../../../src/contracts/USDT_ABI.js";
import { BSC_SPLIT_ABI } from "../../../../src/contracts/BSC_SPLIT_ABI.js";
import { BSC_TOPUP_ABI } from "../../../../src/contracts/BSC_TOPUP_ABI.js";

import Topupbutton from "../../../../src/jsx/components/web3/Topupbutton.js";

const BSC_USDT_CONTRACT = "0x55d398326f99059fF775485246999027B3197955";
const BSC_SPLIT_CONTRACT = "0xB124D8a36C5D657eF0801Ff13fBbe7525cebc897";
const BSC_TOPUP_CONTRACT = "0x42ffD035B5690e0cE8943238124009688FAC8fd2";

const BB_10_WALLET = "0x884381f19A5B2BFB604AEd8a4251Be2f7f76591f";
const CR_10_WALLET = "0x593f27504F2bE394C648F2216319Cb7fC9cf37bE";
const BB_80_WALLET = "0x0bC0A66E75FF4FaE3e449912d0b98129C81a6803";
const BB_CRYPTAPI_80 = "0x4EA8Af9C8851e44dBC2D4b6ff162D93e30F912b6";

const LimitChart = loadable(() =>
  pMinDelay(import("./../Mophy/Dashboard/LimitChart"), 1000)
);
const IncomeChart = loadable(() =>
  pMinDelay(import("./../Mophy/Dashboard/IncomeChart"), 1000)
);
const OutomeChart = loadable(() =>
  pMinDelay(import("./../Mophy/Dashboard/OutomeChart"), 1000)
);
const WeeklyChart = loadable(() =>
  pMinDelay(import("./../Mophy/Dashboard/WeeklyChart"), 1000)
);

const Home = () => {
  const [sendMessage, setSendMessage] = useState(false);
  const { address, isConnecting, isDisconnected } = useAccount();
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [userWallet, setUserWallet] = useState("");
  const user = useSelector((state) => state.user.user);
  const [createdAddress, setCreatedAddress] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [LoadAmount, setLoadAmount] = useState(0);

  const [apiFee, setApiFee] = useState(0);
  const [qrFee, setqrFee] = useState(0);
  const [blockchainFee, setblockchainFee] = useState(0.5);
  const [camFee, setCamFee] = useState(0);
  const [loadOnCard, setLoadOnCard] = useState(0);
  const [fcfFee, setFcfFee] = useState(0);
  const [toFcf, setToFcf] = useState(0);
  const [toFcfPercentage, setToFcfPercentage] = useState(0);
  const [toCamPercentage, setToCamPercentage] = useState(0);
  const [topupref, settopupref] = useState(0);

  const [fcftopayaddress, setfcftopayaddress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [cardBalance, setcardBalance] = useState(0);
  const [issuer_Last4, setissuer_Last4] = useState("0000");
  const [card_status, setcard_status] = useState("");

  const [net_topup, setnet_topup] = useState(0);
  const [refreshloading, setrefreshloading] = useState(false);
  const [minAmount, setminAmount] = useState(0);
  const [real_amount, setreal_amount] = useState(0);
  //FIRST STEPS
  const [goSteps, setGoSteps] = useState(0);

  //TOPUPCARD
  const [isLoadingTransaction, setisLoadingTransaction] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [address_in, setaddressin] = useState("");
  const [txn_hash, settxn_hash] = useState("");
  const [confirmations, setconfirmations] = useState(0);
  const [success_message, setsuccess_message] = useState(false);
  const [success_message_pin, setsuccess_message_pin] = useState(false);
  const [old_balance, set_old_balance] = useState(false);
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState("");
  const [network, setNetwork] = useState("");

  const [bb_marketingAmount, setbb_marketingAmount] = useState(0);
  const [bb_crAmount, setbb_crAmount] = useState(0);
  const [bb_teamAmount, setbb_teamAmount] = useState(0);
  const [topupsended, settopupsended] = useState(false);

  const [isLoadingTransactionMATIC, setisLoadingTransactionMATIC] =
    useState(false);
  const [isLoadingTransactionBSC, setisLoadingTransactionBSC] = useState(false);

  const loadingfees = {
    1: [0.0499, 5],
    2: [0.0399, 4],
    3: [0.0348, 3],
    10: [0, 0],
  };

  const handleonPinChanged = () => {
    setShow(true);
    setsuccess_message_pin(true);
    setGoSteps(1);
  };

  const handleStatusChange = (status) => {
    console.log("handleStatusChange", status);
    setStatus(status);
  };

  const handleTopUpSuccess = async () => {
    console.log("handleTopUpSuccess");
    setsuccess_message(true);
    set_old_balance(cardBalance);
    const interval = setInterval(() => {
      handlerefreshbalance();
    }, 15000);

    return () => clearInterval(interval);
  };

  useEffect(() => {
    if (old_balance != 0) {
      if (cardBalance != old_balance) {
        console.log("Balance refreshed");
        setrefreshloading(false);
      }
    }
  }, [cardBalance]);

  useEffect(() => {
    if (user) {
      //   console.log("set user", JSON.stringify(user));
      localStorage.setItem("user", JSON.stringify(user));
      setLocalUser(user);
    }
  }, [user]);

  const handlerefreshbalance = async () => {
    setrefreshloading(true);
    const balance = await fetchcardbalance();
    if (balance !== cardBalance) {
      console.log("Balance changed");
      if (localUser?.cards?.bb_active == "0") {
        console.log("Card is inactive");
        const data = {
          userId: localUser.id,
        };

        fetch("https://api.bridge-bit.com/api/update_card_status", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .finally(() => {
            console.log("bb active successful DB");
            setcard_status("1");
          });
      } else {
        console.log("Card active");
      }
      setrefreshloading(false);
      window.location.reload();
    }
    setcardBalance(balance);
    setrefreshloading(false);
  };

  useEffect(() => {
    console.log("fetching Balance");
    console.log(localUser);

    const fetchBalance = async () => {
      if (localUser?.cards?.cardKitNumber) {
        setcard_status(localUser.cards.bb_active);
        setrefreshloading(true);
        const balance = await fetchcardbalance();
        setcardBalance(balance);
        setissuer_Last4(localUser?.cards?.issuer_Last4);
        setrefreshloading(false);
        if (localUser.cards.initial_pin === "1") {
          setGoSteps(1);
        }
      }
    };

    fetchBalance();
  }, []);

  useEffect(() => {
    console.log("useEffect Loadmount");
    console.log("Address", address);
    let min_amount;
    let fix_fee;
    let real_amount;
    if (localUser?.cards?.bb_active == "0") {
      setminAmount(100);
      min_amount = 100;
      fix_fee = 20;
      real_amount = LoadAmount - fix_fee;
      setreal_amount(real_amount);
    } else {
      setminAmount(50);
      min_amount = 50;
      fix_fee = 0;
      real_amount = LoadAmount;
      setreal_amount(real_amount);
    }
    if (LoadAmount >= min_amount) {
      let InputAmount = LoadAmount - fix_fee;
      console.log("InputAmount", InputAmount);
      let cryptapifee = InputAmount * 0.01;
      console.log("cryptapifee", cryptapifee);

      let bridgetloadingfee =
        InputAmount * loadingfees[localUser?.cards?.card_type][0] +
        loadingfees[localUser?.cards?.card_type][1];
      console.log("bridgetloadingfee", bridgetloadingfee);

      let net_card_amount;
      if (address !== undefined) {
        net_card_amount = InputAmount - bridgetloadingfee;
      } else {
        net_card_amount = InputAmount - bridgetloadingfee - cryptapifee - 0.5;
      }
      console.log("net_card_amount", net_card_amount);
      //   let fcf_fee = net_card_amount * 0.0275 + 3;
      //   console.log("fcf_fee", fcf_fee);
      //   let net_card_loading_amount = net_card_amount - fcf_fee;
      //   console.log("net_card_loading_amount", net_card_loading_amount);

      //   //TOPUP SPLITTING
      //   let bb_amount = InputAmount - net_card_amount;
      //   console.log("bb_amount", bb_amount);
      //   let bb_marketing = bb_amount * 0.8;
      //   console.log("bb_marketing", bb_marketing);
      //   let bb_cr = bb_amount * 0.1;
      //   console.log("bb_cr", bb_cr);
      //   let bb_team = bb_amount * 0.1;
      //   console.log("bb_team", bb_team);
      //   let control = bb_marketing + bb_cr + bb_team;
      //   console.log("control", control);

      setnet_topup(net_card_amount.toFixed(2));
      setToFcf(net_card_amount.toFixed(2));
    }
  }, [LoadAmount]);

  const fetchUserTransactions = async () => {
    try {
      console.log("address in", address_in);
      const response = await fetch(
        `https://api.bridge-bit.com/api/user-transactions/${address_in}`
      );
      const data = await response.json();
      console.log("User transactions:", data);
      settxn_hash(data.txid_in);
      setconfirmations(data.confirmations);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setTransactions(response.data);
      if (response.data) {
        let forwarded_coin = response.data.value_forwarded_coin;
        console.log("Forwardediting coin", forwarded_coin);
        let bb_amount = forwarded_coin * toCamPercentage;
        console.log("bb_amount", bb_amount);
        let bb_split_amount = bb_amount * 0.8;
        console.log("bb_split_amount", bb_split_amount);
        setbb_marketingAmount(bb_split_amount);
      }

      if (data.status == "confirmed") {
        setisLoadingTransaction(false);
        sendTopUpData();
      }
    } catch (error) {
      console.error("Error fetching user transactions:", error);
    }
  };

  useEffect(() => {
    setUserWallet(address);
  }, [address]);

  useEffect(() => {
    if (address_in !== "" && isLoadingTransaction) {
      const interval = setInterval(() => {
        fetchUserTransactions();
        fetchcardbalance();
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [address_in]);

  const handlesetNetwork = (target) => {
    console.log("Network:", target);
    setNetwork(target);

    createapiaddress(target);
  };

  const createapiaddress = async (network) => {
    console.log("network", network);
    setIsLoading(true);
    setisLoadingTransaction(true);

    let fcf_network;
    let crypt_network;
    if (network === "bep20") {
      fcf_network = "BSC.USDT";
      crypt_network = "bep20/usdt";
      setisLoadingTransactionBSC(true);
    } else if (network === "matic") {
      fcf_network = "MATIC.USDT";
      crypt_network = "polygon/usdt";
      setisLoadingTransactionMATIC(true);
    }

    try {
      let amount = LoadAmount;
      console.log("amount", amount);

      //   1. Schritt Definition Netto Ladebetrag Karte (das was der Kunde dann tatsächlich auf die Karte bekommt)
      // 	—> Sendebetrag - BridgeBit Loading Fee (Prozentual + Absolut) - 1% cryptapi = Net Card Loading

      let cryptapifee = amount * 0.01;
      console.log("cryptapifee", cryptapifee);
      let bridgetloadingfee =
        amount * loadingfees[localUser?.cards?.card_type][0] +
        loadingfees[localUser?.cards?.card_type][1];
      console.log("bridgetloadingfee", bridgetloadingfee);
      let net_card_amount;
      if (address) {
        net_card_amount = amount - bridgetloadingfee;
      } else {
        net_card_amount = amount - bridgetloadingfee - cryptapifee - 0.5;
      }
      console.log("net_card_amount", net_card_amount);
      setnet_topup(net_card_amount.toFixed(2));

      // 	2. Definition Absoluter Betrag FCF, um prozentuale Aufteilung zu ermitteln
      // 	—> Net Card Loading + FCF Top Up Fee (Prozentual (2.75%  + Absolut (3 usd)

      const apiResponse = await fetchDepositData(
        localUser.cards.cardKitNumber,
        net_card_amount.toFixed(2),
        fcf_network
      );

      console.log("apiResponse", apiResponse);
      setfcftopayaddress(apiResponse.data.address);

      let fcf_amount = apiResponse.data.crypto_currency_amount;
      setToFcf(fcf_amount);

      // 	3. Definition Absoluter Betrag BridgeBit um prozentuale Aufteilung zu ermitteln
      // 	—> Sendebetrag - 2. Absoluter Betrag FCF

      let bridgebit_amount = amount - fcf_amount;
      console.log("bridgebit_amount", bridgebit_amount);
      let topupsplit = bridgebit_amount * 0.8;
      settopupref(topupsplit);
      console.log("topupsplit", topupsplit);

      // 	4. Prozentuale Aufteilung
      // 	1. Absoluter FCF Betrag / (Sendebetrag - Blockchain Fee)

      var to_fcf_percentage = fcf_amount / (amount * 0.99 - 0.5);
      console.log("to_fcf_percentage", to_fcf_percentage);
      setToFcfPercentage(to_fcf_percentage);

      // 	2. Absoluter BB Betrag / (Sendebetrag - Blockchain Fee)

      var to_cam_percentage = bridgebit_amount / (amount * 0.99 - 0.5);
      console.log("to_cam_percentage", to_cam_percentage);
      setbb_marketingAmount(bridgebit_amount * 0.8);
      setbb_crAmount(bridgebit_amount * 0.1);
      setbb_teamAmount(bridgebit_amount * 0.1);

      let temp = Math.ceil(to_fcf_percentage * 10000) / 10000;
      let perc_to_fcf = temp.toFixed(4);
      console.log("test", perc_to_fcf);
      //   var perc_to_fcf = (Math.ceil(to_fcf_percentage * 100) / 100).toFixed(4);
      var perc_to_cam = (1 - perc_to_fcf).toFixed(4);
      setToCamPercentage(perc_to_cam);

      console.log(perc_to_fcf);
      console.log(perc_to_cam);

      const query = new URLSearchParams({
        callback: `http://api.bridge-bit.com/api/cryptapi-callback?topup=1?user_id=${
          localUser.id
        }&time=${Math.floor(Date.now() / 1000)}`, // Replace with actual callback URL
        multi_token: 1,
        address:
          perc_to_cam +
          "@" +
          BB_CRYPTAPI_80 +
          "|" +
          perc_to_fcf +
          "@" +
          apiResponse.data.address, // Replace with the address to forward payments to
        // ... other parameters
      }).toString();

      const response = await fetch(
        `https://api.cryptapi.io/${crypt_network}/create/?${query}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Address created:", data);

      if (data.status === "success") {
        setaddressin(data.address_in);
        setCreatedAddress(data.address_in);
        fetchQrCode(data.address_in, crypt_network);
        setisLoadingTransaction(true);
      } else {
        // Handle API response error
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error creating new address:", error);
      setIsLoading(false);
    }
  };

  const handleLoadClick = async () => {
    setIsLoading(true);
    setisLoadingTransaction(true);
    try {
      // const fee = await fetchblockhainfee();
      // if (isNaN(fee)) {
      //   console.log("Invalid fee value: ", fee);
      // } else {
      //   console.log("fee", fee);
      //   setblockchainFee(Number(fee));
      // }

      let amount = LoadAmount;
      console.log("amount", amount);

      //   1. Schritt Definition Netto Ladebetrag Karte (das was der Kunde dann tatsächlich auf die Karte bekommt)
      // 	—> Sendebetrag - BridgeBit Loading Fee (Prozentual + Absolut) - 1% cryptapi = Net Card Loading

      let cryptapifee = amount * 0.01;
      console.log("cryptapifee", cryptapifee);
      let bridgetloadingfee =
        amount * loadingfees[localUser?.cards?.card_type][0] +
        loadingfees[localUser?.cards?.card_type][1];
      console.log("bridgetloadingfee", bridgetloadingfee);
      let net_card_amount;
      if (address) {
        net_card_amount = amount - bridgetloadingfee;
      } else {
        net_card_amount = amount - bridgetloadingfee - cryptapifee - 0.5;
      }
      console.log("net_card_amount", net_card_amount);

      // 	2. Definition Absoluter Betrag FCF, um prozentuale Aufteilung zu ermitteln
      // 	—> Net Card Loading + FCF Top Up Fee (Prozentual (2.75%  + Absolut (3 usd)

      let fcf_fee = net_card_amount * 0.0275 + 3;
      console.log("fcf_fee", fcf_fee);
      let net_card_loading_amount = net_card_amount - fcf_fee;
      console.log("net_card_loading_amount", net_card_loading_amount);

      const apiResponse = await fetchDepositData(
        localUser.cards.cardKitNumber,
        net_card_amount.toFixed(2),
        "BSC.USDT"
      );
      console.log("apiResponse", apiResponse);
      setfcftopayaddress(apiResponse.data.address);

      let fcf_amount = apiResponse.data.crypto_currency_amount;
      setToFcf(fcf_amount);

      // 	3. Definition Absoluter Betrag BridgeBit um prozentuale Aufteilung zu ermitteln
      // 	—> Sendebetrag - 2. Absoluter Betrag FCF

      let bridgebit_amount = amount - fcf_amount;
      console.log("bridgebit_amount", bridgebit_amount);
      let topupsplit = bridgebit_amount * 0.8;
      settopupref(topupsplit);
      console.log("topupsplit", topupsplit);

      // 	4. Prozentuale Aufteilung
      // 	1. Absoluter FCF Betrag / (Sendebetrag - Blockchain Fee)

      var to_fcf_percentage = fcf_amount / (amount * 0.99 - 0.5);
      console.log("to_fcf_percentage", to_fcf_percentage);
      setToFcfPercentage(to_fcf_percentage);

      // 	2. Absoluter BB Betrag / (Sendebetrag - Blockchain Fee)

      var to_cam_percentage = bridgebit_amount / (amount * 0.99 - 0.5);
      console.log("to_cam_percentage", to_cam_percentage);
      setToCamPercentage(to_cam_percentage);

      let temp = Math.ceil(to_fcf_percentage * 10000) / 10000;
      let perc_to_fcf = temp.toFixed(4);
      console.log("test", perc_to_fcf);
      //   var perc_to_fcf = (Math.ceil(to_fcf_percentage * 100) / 100).toFixed(4);
      var perc_to_cam = (1 - perc_to_fcf).toFixed(4);
      console.log(perc_to_fcf);
      console.log(perc_to_cam);

      const query = new URLSearchParams({
        callback: `http://api.bridge-bit.com/api/cryptapi-callback?user_id=${
          localUser.id
        }&time=${Math.floor(Date.now() / 1000)}`, // Replace with actual callback URL
        multi_token: 1,
        address:
          perc_to_cam +
          "@" +
          BB_CRYPTAPI_80 +
          "|" +
          perc_to_fcf +
          "@" +
          apiResponse.data.address, // Replace with the address to forward payments to
        // ... other parameters
      }).toString();

      const ticker = "bep20/usdt"; // Replace with your ticker (e.g., 'btc', 'eth', etc.)
      const response = await fetch(
        `https://api.cryptapi.io/${ticker}/create/?${query}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Address created:", data);

      if (data.status === "success") {
        setaddressin(data.address_in);
        setCreatedAddress(data.address_in);
        fetchQrCode(data.address_in);
        setisLoadingTransaction(true);
      } else {
        // Handle API response error
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error creating new address:", error);
      setIsLoading(false);
    }
  };

  async function fetchDepositData(cardNumber, amount, tokenSymbol) {
    console.log("cardNumber", cardNumber);
    console.log("amount", amount.toString());
    console.log("tokenSymbol", tokenSymbol);
    const response = await axios.post(
      "https://dashboard.croesus-asset.management/api/physical-cards/load-deposit",
      {
        card_number: cardNumber,
        amount: amount.toString(),
        token_symbol: tokenSymbol,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization:
            "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
        },
      }
    );

    if (!response.status === 200) {
      throw new Error("Network response was not ok");
    }

    return response.data;
  }

  const fetchcardbalance = async () => {
    const response = await axios.post(
      "https://dashboard.croesus-asset.management/api/physical-cards/get-balance",
      {
        card_number: localUser.cards.cardKitNumber,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization:
            "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
        },
      }
    );

    if (!response.status === 200) {
      throw new Error("Network response was not ok");
    }
    console.log("balance update", response.data.message);
    return response.data.message;
  };

  const fetchblockhainfee = async (address) => {
    try {
      const response = await fetch(
        `https://api.cryptapi.io/bep20/usdt/estimate/?addresses=2&priority=default`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("fetchblockhainfee:", data.estimated_cost_currency.USD);
      return data.estimated_cost_currency.USD;
    } catch (error) {
      console.error("Error fetching fetchblockhainfee:", error);
    }
  };

  const fetchQrCode = async (address, network) => {
    try {
      var value = LoadAmount;
      const query = new URLSearchParams({
        address: address,
        value: value,
        size: "512",
      }).toString();

      const response = await fetch(
        `https://api.cryptapi.io/${network}/qrcode/?${query}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("QR Code data:", data);

      // Assuming the QR code data contains a base64 encoded image
      if (data.qr_code) {
        setQrCode(data.qr_code); // Update the state with the QR code data
      }
    } catch (error) {
      console.error("Error fetching QR code:", error);
    }
  };

  async function sendTopUpData() {
    console.log("sendTopUpData");
    if (!topupsended) {
      settopupsended(true);
      axios
        .post("https://api.bridge-bit.com/api/split_topup", {
          user: localUser.id,
          currency: network,
          amount: bb_marketingAmount,
          brut_amount: LoadAmount,
          net_amount: net_topup,
          fcf_amount: toFcf,
          bb_amount: bb_marketingAmount,
          marketing_amount: bb_marketingAmount,
          txn_hash: txn_hash,
        })
        .then(async (response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <Fragment>
      <div className="form-head mb-4">
        <h2 className="text-black font-w600 mb-0">Card Management</h2>
      </div>
      {localUser?.cards?.cardKitNumber ? (
        <>
          {card_status == "1" ? (
            <></>
          ) : (
            <div className="row">
              <div className="col-xl-12 col-xxl-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      First Steps With Your Bridge Bit Card
                    </h4>
                  </div>
                  <div className="card-body">
                    <div className="form-wizard ">
                      <Stepper
                        className="nav-wizard"
                        activeStep={goSteps}
                        label={false}
                      >
                        <Step
                          className="nav-link"
                          onClick={() => setGoSteps(0)}
                        />
                        <Step
                          className="nav-link"
                          onClick={() => setGoSteps(1)}
                        />
                        {/* <Step className="nav-link" onClick={() => setGoSteps(2)} /> */}
                      </Stepper>
                      {goSteps === 0 && (
                        <>
                          <h4 className="card-title text-center">
                            SET PERSONAL PIN
                          </h4>
                          <StepOne
                            localUser={localUser}
                            onPinChanged={handleonPinChanged}
                          />

                          {/* <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        className="btn btn-primary sw-btn-next"
                        onClick={() => setGoSteps(1)}
                      >
                        Next
                      </button>
                    </div> */}
                        </>
                      )}
                      {goSteps === 1 && (
                        <>
                          <div className="text-center mb-3">
                            <h4 className="card-title text-center">
                              INITIAL TOP UP
                            </h4>
                            <label className="text-black font-w600 ">
                              To activate your card you have to top up at least
                              $100 or more. The activation fee of 20$ will be
                              automatically substracted from your initial top
                              up.
                            </label>
                          </div>
                          <StepTwo localUser={localUser} />
                          {/* <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        className="btn btn-secondary sw-btn-prev me-1"
                        onClick={() => setGoSteps(0)}
                      >
                        Prev
                      </button>
                      <button
                        className="btn btn-primary sw-btn-next ms-1"
                        onClick={() => setGoSteps(2)}
                      >
                        Next
                      </button>
                    </div> */}
                        </>
                      )}
                      {goSteps === 2 && (
                        <>
                          {/* <StepThree /> */}
                          <div className="text-end toolbar toolbar-bottom p-2">
                            <button
                              className="btn btn-secondary sw-btn-prev me-1"
                              onClick={() => setGoSteps(1)}
                            >
                              Prev
                            </button>
                            <button
                              className="btn btn-primary sw-btn-next ms-1"
                              onClick={() => setGoSteps(3)}
                            >
                              Next
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {success_message_pin || localUser?.cards?.initial_pin === "1" ? (
            <>
              {show && (
                <Alert variant="success" dismissible className="text-center">
                  <strong>Success! </strong> Initial PIN set.
                  <button
                    className="btn-close"
                    onClick={() => setShow(false)}
                  ></button>
                </Alert>
              )}
              <div className="row">
                <div className="col-xl-8 col-lg-12 col-sm-12">
                  <div className="row">
                    <div className="col-xl-8 col-lg-6 col-md-7 col-sm-8">
                      <div
                        className="card-bx stacked"
                        style={{ minWidth: "270px" }}
                      >
                        <img
                          src={visa}
                          alt=""
                          className="mw-100 "
                          style={{
                            height: "auto",
                            minHeight: "270px",
                          }}
                        />
                        <div className="card-info text-white">
                          <div
                            className="d-flex align-items-center mb-sm-1 mb-1"
                            style={{ justifyContent: "space-between" }}
                          >
                            <p className="mb-1">Card Balance</p>
                            {card_status == "1" ? (
                              <p className="mb-1 text-success font-w600">
                                Active
                              </p>
                            ) : (
                              <p className="mb-1 text-danger font-w600">
                                Inactive
                              </p>
                            )}
                          </div>
                          <div
                            className="d-flex align-items-center mb-sm-1 mb-1"
                            style={{ justifyContent: "space-between" }}
                          >
                            <h2 className="fs-36 text-white mb-sm-2 mb-3">
                              {refreshloading ? (
                                <div className="loadingDots">
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </div>
                              ) : (
                                `$${cardBalance}`
                              )}
                            </h2>
                            <button
                              className="btn btn-light ml-2 p-2"
                              onClick={handlerefreshbalance}
                            >
                              <i
                                className={`fas fa-sync-alt ${
                                  refreshloading ? "fa-spin" : ""
                                }`}
                                style={{ fontSize: "25px" }}
                              ></i>
                            </button>
                          </div>

                          {LoadAmount < minAmount ? (
                            <div
                              className="d-flex align-items-center mb-sm-2 mb-2"
                              style={{ justifyContent: "space-between" }}
                            >
                              <span className="fs-14 text-danger mb-0">
                                Min. Amount {minAmount}
                              </span>

                              <h4 className="fs-20 text-white mb-0">
                                **** **** **** {issuer_Last4}
                              </h4>
                            </div>
                          ) : (
                            <div
                              className="d-flex align-items-center mb-sm-2 mb-2"
                              style={{ justifyContent: "end" }}
                            >
                              <h4 className="fs-20 text-white mb-0">
                                **** **** **** {issuer_Last4}
                              </h4>
                            </div>
                          )}
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control h-auto text-black"
                                placeholder="Enter Amount"
                                onChange={(e) => setLoadAmount(e.target.value)}
                              />
                              {/* <button
                        onClick={handecalc}
                        className="btn btn-light"
                        type="button"
                        style={{ height: "40px" }}
                      >
                        Calc
                      </button>{" "} */}

                              {address ? (
                                <Topupbutton
                                  LoadAmount={real_amount}
                                  LoadAmountNet={net_topup}
                                  LoadAmountFCF={toFcf}
                                  onTopUpSuccess={handleTopUpSuccess}
                                  onStatusChange={handleStatusChange}
                                />
                              ) : (
                                <></>
                                // <button
                                //   onClick={handleLoadClick}
                                //   className="btn btn-light"
                                //   type="button"
                                //   disabled={LoadAmount === 0 ? true : false}
                                // >
                                //   {isLoading ? (
                                //     <Spinner
                                //       animation="border"
                                //       size="sm"
                                //       role="status"
                                //       aria-hidden="true"
                                //       className="me-2"
                                //     ></Spinner>
                                //   ) : (
                                //     <div>TopUp</div>
                                //   )}
                                // </button>
                              )}
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-xl-4 col-lg-6 col-md-5 col-sm-4">
              <div
                className="card bgl-primary card-body overflow-hidden p-0 d-flex rounded"
                style={{ maxHeight: "280px" }}
              >
                <div className="p-0 text-center mt-3">
                  <span className="text-black">Daily Limit</span>
                  <h3 className="text-black fs-20 mb-0 font-w600">$4,000</h3>
                  <small>/$10,000</small>
                </div>
                <div className="mt-auto line-chart-demo">
                  <LimitChart />
                </div>
              </div>
            </div> */}

                    {/* <div className="col-xl-12">
              <div className="card">
                <div className="card-header d-sm-flex d-block border-0 pb-0">
                  <div className="pr-3 mb-sm-0 mb-3 me-auto">
                    <h4 className="fs-20 text-black mb-1">Quick Transfer</h4>
                    <span className="fs-12">
                      Lorem ipsum dolor sit amet, consectetur
                    </span>
                  </div>
                  <span className="fs-24 text-black font-w600">$56,772.38</span>
                </div>
                <div className="card-body">
                  <TransferOwl />
                  <form>
                    <div className="form-group row style-1 align-items-center">
                      <label className="fs-18 col-sm-3 text-black font-w500">
                        Amount
                      </label>
                      <div className="input-group col-sm-9">
                        <input type="number" className="form-control" />
                        <div className="input-group-append">
                          <button
                            className="btn btn-primary rounded"
                            type="button"
                          >
                            TRANSFER NOW
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div> */}
                  </div>
                </div>
                <div className="mt-3 mt-md-5">
                  <Col xl="8">
                    <div className="row">
                      <Col xl="8" sm="8" lg="6">
                        <Card>
                          {/* <Card.Header className=" border-0 pb-0">
                    <Card.Title></Card.Title>
                  </Card.Header> */}
                          {net_topup != 0 && (
                            <Card.Body
                              className="d-flex text-center"
                              style={{
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <>
                                <>
                                  {userWallet !== "" ? (
                                    <>
                                      <p className="fs-18 mb-2 text-black">
                                        {status}
                                      </p>
                                      <p className="fs-18 mb-1 text-black">
                                        Fee:{" "}
                                        {(
                                          loadingfees[
                                            localUser?.cards?.card_type
                                          ][0] * 100
                                        ).toFixed(2)}
                                        % + $
                                        {
                                          loadingfees[
                                            localUser?.cards?.card_type
                                          ][1]
                                        }
                                      </p>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  <p className="fs-18 mb-3 text-black">
                                    NET TOP UP: ${net_topup}
                                  </p>
                                </>
                                {userWallet === "" ? (
                                  <div
                                    className="col-lg-12 d-flex"
                                    style={{
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <label className="text-black font-w600 ">
                                      Select Blockchain
                                    </label>
                                    <div
                                      className="row"
                                      style={{
                                        display: "flex",
                                        flexDirection: "row", // Change from 'column' to 'row'
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div className="col-6">
                                        <button
                                          value="bep20"
                                          onClick={(e) =>
                                            handlesetNetwork("bep20")
                                          }
                                          className="btn btn-primary mt-3 m-1"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "max-content",
                                          }}
                                          //   disabled={network && network === "bep20"}
                                          disabled={isLoadingTransactionMATIC}
                                        >
                                          {isLoadingTransactionBSC ? (
                                            <>
                                              <Spinner
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className="me-2"
                                              ></Spinner>
                                              USDT BEP20
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                src="https://api.cryptapi.io/media/token_logos/bnb_logo_otj5BsU.png"
                                                alt="network"
                                                style={{
                                                  marginRight: "10px",
                                                  width: "25px",
                                                }}
                                              />
                                              USDT BEP20
                                            </>
                                          )}
                                        </button>
                                      </div>
                                      <div className="col-6">
                                        <button
                                          value="matic"
                                          onClick={(e) =>
                                            handlesetNetwork("matic")
                                          }
                                          className="btn btn-primary mt-3 m-1"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "max-content",
                                          }}
                                          //   disabled={network && network === "matic"}
                                          disabled={isLoadingTransactionBSC}
                                        >
                                          {isLoadingTransactionMATIC ? (
                                            <>
                                              <Spinner
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className="me-2"
                                              ></Spinner>
                                              USDT MATIC
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                src="https://api.cryptapi.io/media/token_logos/polygon_logo_ZZ69slV.png"
                                                alt="network"
                                                style={{
                                                  marginRight: "10px",
                                                  width: "25px",
                                                }}
                                              />
                                              USDT MATIC
                                            </>
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <div className="d-flex flex-column">
                                  {net_topup != 0 && (
                                    <>
                                      {success_message ? (
                                        <div
                                          className="alert alert-success"
                                          role="alert"
                                        >
                                          Transaction Confirmed. Please stay on
                                          that page. You card balance will
                                          update automatically.
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                </div>
                                {createdAddress && (
                                  <>
                                    <label className="text-black font-w600 ">
                                      Address
                                    </label>
                                    <div className="input-group mb-3 mt-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="0x00000000000000"
                                        value={createdAddress}
                                        readOnly
                                      />

                                      <button
                                        className="btn btn-primary"
                                        type="button"
                                        style={{ height: "40px" }}
                                        onClick={() => {
                                          navigator.clipboard
                                            .writeText(createdAddress)
                                            .then(() =>
                                              alert(
                                                "Address copied to clipboard!"
                                              )
                                            )
                                            .catch((error) =>
                                              console.error(
                                                "Could not copy text: ",
                                                error
                                              )
                                            );
                                        }}
                                      >
                                        COPY
                                      </button>
                                    </div>
                                    <label className="text-black font-w600 ">
                                      Amount
                                    </label>
                                    <div
                                      className="input-group mb-3 mt-3"
                                      style={{ maxWidth: "200px" }}
                                    >
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="0"
                                        value={LoadAmount}
                                        readOnly
                                      />

                                      <button
                                        className="btn btn-primary"
                                        type="button"
                                        style={{ height: "40px" }}
                                        onClick={() => {
                                          navigator.clipboard
                                            .writeText(LoadAmount)
                                            .then(() =>
                                              alert(
                                                "Amount copied to clipboard!"
                                              )
                                            )
                                            .catch((error) =>
                                              console.error(
                                                "Could not copy text: ",
                                                error
                                              )
                                            );
                                        }}
                                      >
                                        COPY
                                      </button>
                                    </div>
                                    {qrCode && (
                                      <div
                                        className="d-flex mb-3"
                                        style={{ justifyContent: "center" }}
                                      >
                                        <img
                                          src={`data:image/png;base64,${qrCode}`}
                                          alt="QR Code"
                                          style={{ maxWidth: "220px" }}
                                        />
                                      </div>
                                    )}
                                    {isLoadingTransaction ? (
                                      <>
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          role="status"
                                          aria-hidden="true"
                                          className="me-2"
                                        />
                                        {txn_hash ? (
                                          <>
                                            <label className="text-black font-w600 mt-3">
                                              Transaction Hash
                                            </label>
                                            <label className="text-black font-w600 mt-3">
                                              {txn_hash.substring(0, 15)}...
                                            </label>
                                            <label className="text-black font-w600 mt-3">
                                              Confirmations : {confirmations}
                                            </label>
                                          </>
                                        ) : (
                                          <>
                                            <label className="text-black font-w600 mt-3">
                                              Waiting for Payment
                                            </label>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <div
                                        className="alert alert-success"
                                        role="alert"
                                      >
                                        Transaction Confirmed
                                      </div>
                                    )}
                                  </>
                                )}
                              </>
                            </Card.Body>
                          )}

                          {/* <Card.Footer className=" border-0 pt-0">
                    <Card.Text className=" d-inline">Card footer</Card.Text>
                  </Card.Footer> */}
                        </Card>
                      </Col>
                    </div>
                  </Col>
                </div>

                <div className="col-xl-6">
                  <div className="row">
                    {/* <div className="col-xl-6 col-sm-6">
              <div className="card">
                <div className="card-header flex-wrap border-0 pb-0">
                  <div className="me-3 mb-2">
                    <p className="fs-14 mb-1">Income</p>
                    <span className="fs-24 text-black font-w600">$65,123</span>
                  </div>
                  <span className="fs-12 mb-2">
                    <svg
                      className=""
                      width="21"
                      height="15"
                      viewBox="0 0 21 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.999939 13.5C1.91791 12.4157 4.89722 9.22772 6.49994 7.5L12.4999 10.5L19.4999 1.5"
                        stroke="#2BC155"
                        strokeWidth="2"
                      />
                      <path
                        d="M6.49994 7.5C4.89722 9.22772 1.91791 12.4157 0.999939 13.5H19.4999V1.5L12.4999 10.5L6.49994 7.5Z"
                        fill="url(#paint0_linear)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear"
                          x1="10.2499"
                          y1="3"
                          x2="10.9999"
                          y2="13.5"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop
                            offset="0"
                            stopColor="#2BC155"
                            stopOpacity="0.73"
                          />
                          <stop
                            offset="1"
                            stopColor="#2BC155"
                            stopOpacity="0"
                          />
                        </linearGradient>
                      </defs>
                    </svg>
                    4% (30 days)
                  </span>
                </div>
                <div className="card-body p-0">
                  <IncomeChart />
                </div>
              </div>
            </div> */}
                    {/* <div className="col-xl-6 col-sm-6">
              <div className="card">
                <div className="card-header flex-wrap border-0 pb-0">
                  <div className="me-3 mb-2">
                    <p className="fs-14 mb-1">Outome</p>
                    <span className="fs-24 text-black font-w600">$24,551</span>
                  </div>
                  <span className="fs-12 mb-2">
                    <svg
                      width="21"
                      height="15"
                      viewBox="0 0 21 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.3514 7.5C15.9974 9.37169 19.0572 12.8253 20 14H1V1L8.18919 10.75L14.3514 7.5Z"
                        fill="url(#paint0_linear1)"
                      />
                      <path
                        d="M19.5 13.5C18.582 12.4157 15.6027 9.22772 14 7.5L8 10.5L1 1.5"
                        stroke="#FF2E2E"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear1"
                          x1="10.5"
                          y1="2.625"
                          x2="9.64345"
                          y2="13.9935"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop offset="0" stopColor="#FF2E2E" />
                          <stop
                            offset="1"
                            stopColor="#FF2E2E"
                            stopOpacity="0.03"
                          />
                        </linearGradient>
                      </defs>
                    </svg>
                    4% (30 days)
                  </span>
                </div>
                <div className="card-body p-0">
                  <OutomeChart />
                </div>
              </div>
            </div> */}
                    {/* <div className="col-xl-12">
              <div className="card overflow-hidden">
                <div className="card-header d-sm-flex d-block border-0 pb-0">
                  <div className="mb-sm-0 mb-2">
                    <p className="fs-14 mb-1">Weekly Wallet Usage</p>
                    <span className="mb-0">
                      <svg
                        width="12"
                        height="6"
                        viewBox="0 0 12 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.9999 6L5.99994 -2.62268e-07L-6.10352e-05 6"
                          fill="#2BC155"
                        />
                      </svg>
                      <strong className="fs-24 text-black ms-2 me-3">
                        43%
                      </strong>
                      Than last week
                    </span>
                  </div>
                  <span className="fs-12">
                    <svg
                      width="21"
                      height="15"
                      viewBox="0 0 21 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.999939 13.5C1.91791 12.4157 4.89722 9.22772 6.49994 7.5L12.4999 10.5L19.4999 1.5"
                        stroke="#2BC155"
                        strokeWidth="2"
                      />
                      <path
                        d="M6.49994 7.5C4.89722 9.22772 1.91791 12.4157 0.999939 13.5H19.4999V1.5L12.4999 10.5L6.49994 7.5Z"
                        fill="url(#paint0_linear2)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear2"
                          x1="10.2499"
                          y1="3"
                          x2="10.9999"
                          y2="13.5"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop
                            offset="0"
                            stopColor="#2BC155"
                            stopOpacity="0.73"
                          />
                          <stop
                            offset="1"
                            stopColor="#2BC155"
                            stopOpacity="0"
                          />
                        </linearGradient>
                      </defs>
                    </svg>
                    4% (30 days)
                  </span>
                </div>
                <div className="card-body p-0">
                  <WeeklyChart />
                </div>
              </div>
            </div> */}
                    {/* <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-5 col-xxl-12 col-md-5">
                      <h4 className="fs-20 text-black mb-4">Spendings</h4>
                      <div className="row">
                        <div className="d-flex col-xl-12 col-xxl-6  col-md-12 col-sm-6 mb-4">
                          <svg
                            className="me-3"
                            width="14"
                            height="54"
                            viewBox="0 0 14 54"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="-6.10352e-05"
                              width="14"
                              height="54"
                              rx="7"
                              fill="#AC39D4"
                            />
                          </svg>
                          <div>
                            <p className="fs-14 mb-2">Investment</p>
                            <span className="fs-18 font-w500">
                              <span className="text-black me-2">$1,415</span>
                              /$2,000
                            </span>
                          </div>
                        </div>
                        <div className="d-flex col-xl-12 col-xxl-6 col-md-12 col-sm-6 mb-4">
                          <svg
                            className="me-3"
                            width="14"
                            height="54"
                            viewBox="0 0 14 54"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="-6.10352e-05"
                              width="14"
                              height="54"
                              rx="7"
                              fill="#40D4A8"
                            />
                          </svg>
                          <div>
                            <p className="fs-14 mb-2">Installment</p>
                            <span className="fs-18 font-w500">
                              <span className="text-black me-2">$1,567</span>
                              /$5,000
                            </span>
                          </div>
                        </div>
                        <div className="d-flex col-xl-12 col-xxl-6 col-md-12 col-sm-6 mb-4">
                          <svg
                            className="me-3"
                            width="14"
                            height="54"
                            viewBox="0 0 14 54"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="-6.10352e-05"
                              width="14"
                              height="54"
                              rx="7"
                              fill="#1EB6E7"
                            />
                          </svg>
                          <div>
                            <p className="fs-14 mb-2">Restaurant</p>
                            <span className="fs-18 font-w500">
                              <span className="text-black me-2">$487</span>
                              /$10,000
                            </span>
                          </div>
                        </div>
                        <div className="d-flex col-xl-12 col-xxl-6 col-md-12 col-sm-6 mb-4">
                          <svg
                            className="me-3"
                            width="14"
                            height="54"
                            viewBox="0 0 14 54"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="-6.10352e-05"
                              width="14"
                              height="54"
                              rx="7"
                              fill="#461EE7"
                            />
                          </svg>
                          <div>
                            <p className="fs-14 mb-2">Property</p>
                            <span className="fs-18 font-w500">
                              <span className="text-black me-2">$3,890</span>
                              /$4,000
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-7  col-xxl-12 col-md-7">
                      <div className="row">
                        <div className="col-sm-6 mb-4">
                          <div className="bg-secondary rounded text-center p-3">
                            <div className="d-inline-block position-relative donut-chart-sale mb-3">
                              <Donut
                                value={71}
                                backgroundColor="rgb(255, 255, 255)"
                                backgroundColor2="rgba(0,0,0,0.1)"
                              />
                              <small className="text-white">71%</small>
                            </div>
                            <span className="fs-14 text-white d-block">
                              Investment
                            </span>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-4">
                          <div className="bg-success rounded text-center p-3">
                            <div className="d-inline-block position-relative donut-chart-sale mb-3">
                              <Donut
                                value={30}
                                backgroundColor="rgb(255, 255, 255)"
                                backgroundColor2="rgba(0,0,0,0.1)"
                              />
                              <small className="text-white">30%</small>
                            </div>
                            <span className="fs-14 text-white d-block">
                              Installment
                            </span>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-sm-0 mb-4">
                          <div className="border border-2 border-primary rounded text-center p-3">
                            <div className="d-inline-block position-relative donut-chart-sale mb-3">
                              <Donut
                                value={5}
                                backgroundColor="rgb(30, 170, 231)"
                                backgroundColor2="rgba(0,0,0,0.1)"
                              />
                              <small className="text-black">5%</small>
                            </div>
                            <span className="fs-14 text-black d-block">
                              Restaurant
                            </span>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-sm-0 mb-4">
                          <div className="bg-info rounded text-center p-3">
                            <div className="d-inline-block position-relative donut-chart-sale mb-3">
                              <Donut
                                value={96}
                                backgroundColor="rgb(255, 255, 255)"
                                backgroundColor2="rgba(0,0,0,0.1)"
                              />
                              <small className="text-white">96%</small>
                            </div>
                            <span className="fs-14 text-white d-block">
                              Property
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
                    <div className="col-xl-12">
                      <div className="card">{/* <HomeTab /> */}</div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <div className="row">
            <div className="col-xl-8 col-lg-12 col-sm-12">
              <div className="row">
                <div className="col-xl-8 col-lg-6 col-md-7 col-sm-8">
                  <div
                    className="card-bx stacked"
                    style={{ minWidth: "270px" }}
                  >
                    <img
                      src={visa}
                      alt=""
                      className="mw-100 "
                      style={{
                        height: "auto",
                        minHeight: "270px",
                      }}
                    />
                    <div className="card-info text-white">
                      <div
                        className="d-flex align-items-center mb-sm-1 mb-1"
                        style={{ justifyContent: "space-between" }}
                      >
                        <p className="mb-1">Card Balance</p>
                        <p className="mb-1 text-danger font-w600">Inactive</p>
                      </div>
                      <div
                        className="d-flex align-items-center mb-sm-1 mb-1"
                        style={{ justifyContent: "space-between" }}
                      >
                        <h2 className="fs-36 text-white mb-sm-2 mb-3">$0.00</h2>
                      </div>

                      {LoadAmount < minAmount ? (
                        <div
                          className="d-flex align-items-center mb-sm-2 mb-2"
                          style={{ justifyContent: "end" }}
                        >
                          <h4 className="fs-20 text-white mb-0">
                            **** **** **** {issuer_Last4}
                          </h4>
                        </div>
                      ) : (
                        <div
                          className="d-flex align-items-center mb-sm-2 mb-2"
                          style={{ justifyContent: "end" }}
                        >
                          <h4 className="fs-20 text-white mb-0">
                            **** **** **** {issuer_Last4}
                          </h4>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
};
export default Home;
