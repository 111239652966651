import { Fragment, useState, useContext, useEffect } from "react";
import {
  useContractRead,
  useContractWrite,
  useAccount,
  useNetwork,
  useWaitForTransaction,
} from "wagmi";
import { usdt_abi } from "../../../../src/contracts/USDT_ABI.js";
import { BSC_SPLIT_ABI } from "../../../../src/contracts/BSC_SPLIT_ABI.js";
import { BSC_SPLIT_TOPUP_ABI } from "../../../../src/contracts/BSC_SPLIT_TOPUP_ABI.js";
import Web3 from "web3";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";

const BSC_USDT_CONTRACT = "0x55d398326f99059fF775485246999027B3197955";
const BSC_SPLIT_CONTRACT = "0xB124D8a36C5D657eF0801Ff13fBbe7525cebc897";
const BSC_TOPUP_CONTRACT = "0x42ffD035B5690e0cE8943238124009688FAC8fd2";
const BB_10_WALLET = "0x884381f19A5B2BFB604AEd8a4251Be2f7f76591f";
const CR_10_WALLET = "0x593f27504F2bE394C648F2216319Cb7fC9cf37bE";
const BB_80_WALLET = "0x0bC0A66E75FF4FaE3e449912d0b98129C81a6803";

const Topupbutton = ({
  LoadAmount,
  LoadAmountNet,
  LoadAmountFCF,
  onTopUpSuccess,
  onStatusChange,
}) => {
  console.log("LoadAmount Brut", LoadAmount);
  console.log("LoadAmount Net", LoadAmountNet);
  console.log("LoadAmountFCF ", LoadAmountFCF);
  const [fcftopayaddress, setfcftopayaddress] = useState("");
  const [tofcfamount, settofcfamount] = useState(0);
  const { address, isConnecting, isDisconnected } = useAccount();
  const { chain } = useNetwork();
  const [bscBalance, setbscBalance] = useState(0);
  const [bscAllowance, setbscAllowance] = useState(0);
  const [isapproved, setisapproved] = useState(false);
  const [cardLoadAmountWEI, setcardLoadAmountWEI] = useState(0);
  const [cardLoadAmountBrutWEI, setcardLoadAmountBrutWEI] = useState(0);
  const [cardLoadAmount, setcardLoadAmount] = useState(LoadAmount);
  const [cardLoadAmountNet, setcardLoadAmountNet] = useState(LoadAmountNet);
  const [cardLoadAmountNetWei, setcardLoadAmountNetWei] = useState(
    Web3.utils.toWei(LoadAmountNet, "ether")
  );
  const bbfeeAmount = LoadAmount - LoadAmountNet;

  const [bb_marketingAmount, setbb_marketingAmount] = useState(0);
  const [bb_crAmount, setbb_crAmount] = useState(0);
  const [bb_teamAmount, setbb_teamAmount] = useState(0);

  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [isLoading, setisLoading] = useState(false);
  const [message, setMessage] = useState("");

  const { data: readData, isLoading: readLoading } = useContractRead({
    address: BSC_USDT_CONTRACT,
    abi: usdt_abi,
    functionName: "balanceOf",
    args: [address],
  });
  console.log("balanceOf", readData);

  useEffect(() => {
    let amount = LoadAmount;
    console.log("useEffect cardLoadAmount", amount);
    if (amount != 0) {
      let LoadAmountWei = Web3.utils.toWei(amount, "ether");
      console.log("LoadAmountWei", LoadAmountWei);
      setcardLoadAmountWEI(LoadAmountWei);
      setcardLoadAmount(amount);
      let LoadAmountNetWei = Web3.utils.toWei(LoadAmountNet, "ether");
      setcardLoadAmountNetWei(LoadAmountNetWei);
      let LoadAmountBrut;
      if (localUser?.cards?.bb_active == "0") {
        LoadAmountBrut = Web3.utils.toWei(LoadAmount + 20, "ether");
      } else {
        LoadAmountBrut = Web3.utils.toWei(LoadAmount, "ether");
      }
      setcardLoadAmountBrutWEI(LoadAmountBrut);
      console.log("LoadAmount Brut calc", LoadAmount);
      console.log("LoadAmount Brut WEI", LoadAmountBrut);
      console.log("LoadAmount Net calc", LoadAmountNet);

      if (localUser) {
        console.log("localUser", localUser);
      }
    }
  }, [LoadAmount]);

  useEffect(() => {
    if (readData) {
      let bscBalanceETH = Web3.utils.fromWei(String(readData), "ether");
      console.log("bscBalanceETH", bscBalanceETH);
      setbscBalance(bscBalanceETH);
    }
  }, [readData]);

  // READ ALLOWANCE
  const { data: readDataAllowance, isLoading: readLoadingAllowance } =
    useContractRead({
      address: BSC_USDT_CONTRACT,
      abi: usdt_abi,
      functionName: "allowance",
      args: [address, BSC_TOPUP_CONTRACT],
    });
  console.log("allowance", readDataAllowance);

  useEffect(() => {
    if (readDataAllowance) {
      let bscAllowanceETH = Web3.utils.fromWei(
        String(readDataAllowance),
        "ether"
      );
      console.log("bscAllowanceETH", bscAllowanceETH);
      setbscAllowance(bscAllowanceETH);
    }
  }, [readDataAllowance]);

  const {
    data: txhashapprove,
    write: writeValue,
    isLoading: writeLoading,
  } = useContractWrite({
    // mode: "recklesslyUnprepared",
    address: BSC_USDT_CONTRACT,
    abi: usdt_abi,
    functionName: "approve",
    args: [BSC_TOPUP_CONTRACT, cardLoadAmountBrutWEI],
    onSuccess(data) {
      window.scrollTo(0, document.body.scrollHeight);
    },
    onError(error) {
      console.log("Error", error);
      setisLoading(false);
    },
  });

  const {
    data: approvedatareceipt,
    isLoading: approveisPending,
    isSuccess: approveisSuccess,
  } = useWaitForTransaction({
    hash: txhashapprove?.hash,
    onSuccess(data) {
      console.log("onSuccess approve", data);
      onStatusChange("Approved");
      setisapproved(true);
    },
    onerror(error) {
      console.log("onError", error);
      isLoading(false);
    },
  });

  //SEND TO CONTRACT
  const {
    data: topupdata,
    write: topupwrite,
    isLoading: topupisPending,
  } = useContractWrite({
    address: BSC_TOPUP_CONTRACT,
    abi: BSC_SPLIT_TOPUP_ABI,
    functionName: "topUp",
    args: [
      [fcftopayaddress, BB_80_WALLET, CR_10_WALLET, BB_10_WALLET],
      [
        Web3.utils.toWei(tofcfamount, "ether"),
        Web3.utils.toWei(bb_marketingAmount, "ether"),
        Web3.utils.toWei(bb_crAmount, "ether"),
        Web3.utils.toWei(bb_teamAmount, "ether"),
      ],
    ],
    onError(error) {
      console.log("Error", error);
      setisLoading(false);
    },
  });

  const {
    data: topupdataceipt,
    isLoading: topupdataPending,
    isSuccess: topupdataSuccess,
  } = useWaitForTransaction({
    hash: topupdata?.hash,
    onSuccess(data) {
      console.log("onSuccess", data);
      onTopUpSuccess(topupdata?.hash);
      sendTopUpData();
    },
    onerror(error) {
      console.log("onError", error);
      isLoading(false);
    },
  });

  useEffect(() => {
    console.log("useEffect");
    console.log("fcftopayaddress", fcftopayaddress);
    console.log("topupisPending", topupisPending);
    console.log("isapproved", isapproved);
    if (
      fcftopayaddress !== "" &&
      topupisPending === false &&
      isapproved === true
    ) {
      console.log("useEffect cardLoadAmountNet", LoadAmountNet);
      console.log("useEffect bbfeeAmount", bbfeeAmount);
      console.log(
        "useEffect cardLoadAmountNet",
        Web3.utils.toWei(LoadAmountNet, "ether")
      );
      console.log(
        "useEffect bbfeeAmount",
        Web3.utils.toWei(bbfeeAmount, "ether")
      );

      const args = [
        [fcftopayaddress, BB_80_WALLET, CR_10_WALLET, BB_10_WALLET],
        [
          Web3.utils.toWei(tofcfamount, "ether"),
          Web3.utils.toWei(bb_marketingAmount, "ether"),
          Web3.utils.toWei(bb_crAmount, "ether"),
          Web3.utils.toWei(bb_teamAmount, "ether"),
        ],
      ];
      console.log("TopUp Args", args);

      topupwrite?.();
    }
  }, [fcftopayaddress, isapproved]);

  useEffect(() => {
    if (writeLoading) {
      onStatusChange("Confirm approve in your wallet");
    } else if (topupisPending) {
      onStatusChange("Confirm TopUp in wallet");
    }
  }, [writeLoading, topupisPending]);

  const handleSetValue = async () => {
    setisLoading(true);
    console.log("handleSetValue");
    console.log(bscAllowance);
    console.log("cardLoadAmountNet", LoadAmountFCF);
    console.log("bbfeeAmount", bbfeeAmount);
    let numbercardloadamount = Number(LoadAmountFCF);

    const apiResponse = await fetchDepositData(
      localUser.cards.cardKitNumber,
      numbercardloadamount.toFixed(2),
      "BSC.USDT"
    );
    console.log("apiResponse", apiResponse);
    settofcfamount(apiResponse.data.crypto_currency_amount);

    //TOPUP SPLITTING
    let bb_amount = LoadAmount - apiResponse.data.crypto_currency_amount;
    console.log("LoadAmount", apiResponse.data.crypto_currency_amount);
    console.log("LoadAmount", Number(LoadAmount));
    console.log("bb_amount", bb_amount);
    let bb_marketing = (bb_amount * 0.8).toFixed(10);
    setbb_marketingAmount(bb_marketing);
    console.log("bb_marketing", bb_marketing);
    let bb_cr = (bb_amount * 0.1).toFixed(10);
    setbb_crAmount(bb_cr);
    console.log("bb_cr", bb_cr);
    let bb_team;
    let to_allow;
    if (localUser?.cards?.bb_active == "0") {
      bb_team = (bb_amount * 0.1 + 20).toFixed(10);
      to_allow = cardLoadAmount + 20;
    } else {
      bb_team = (bb_amount * 0.1).toFixed(10);
      to_allow = cardLoadAmount;
    }
    // let bb_team = (bb_amount * 0.1).toFixed(10);
    setbb_teamAmount(bb_team);
    console.log("bb_team", bb_team);
    let control = Number(LoadAmount) + bb_marketing + bb_cr + bb_team;
    console.log("control", control);

    console.log("Check Allowance");
    console.log("Number(bscAllowance)", Number(bscAllowance));
    console.log("Number(cardLoadAmount)", Number(to_allow));

    window.scrollTo(0, document.body.scrollHeight);
    if (Number(bscAllowance) < Number(to_allow)) {
      console.log("Not enough Allowance");
      setfcftopayaddress(apiResponse.data.address);
      onStatusChange("Need to approve USDT Amount");
      await writeValue();
    } else {
      setisapproved(true);
      setfcftopayaddress(apiResponse.data.address);
    }
  };

  async function sendTopUpData() {
    axios
      .post("https://api.bridge-bit.com/api/split_topup", {
        user: localUser.id,
        currency: "bsc_usdt",
        amount: bb_marketingAmount,
        brut_amount: LoadAmount,
        net_amount: LoadAmountNet,
        fcf_amount: LoadAmountFCF,
        bb_amount: bb_marketingAmount,
        marketing_amount: bb_marketingAmount,
        txn_hash: topupdata?.hash,
      })
      .then(async (response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function fetchDepositData(cardNumber, amount, tokenSymbol) {
    console.log("cardNumber", cardNumber);
    console.log("amount", amount.toString());
    console.log("tokenSymbol", tokenSymbol);
    const response = await axios.post(
      "https://dashboard.croesus-asset.management/api/physical-cards/load-deposit",
      {
        card_number: cardNumber,
        amount: amount.toString(),
        token_symbol: tokenSymbol,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization:
            "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
        },
      }
    );

    if (!response.status === 200) {
      throw new Error("Network response was not ok");
    }

    return response.data;
  }

  return (
    <button
      className="btn btn-light"
      onClick={handleSetValue}
      disabled={isLoading}
      // style={{ height: "40px" }}
    >
      {isLoading ? (
        <Spinner
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          className="me-2"
        ></Spinner>
      ) : (
        "TopUp"
      )}
    </button>
  );
};

export default Topupbutton;
