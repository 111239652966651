import React, { Fragment, useState, useContext, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";

const StepOne = ({ onPinChanged }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [cardPin, setCardPin] = useState("");
  const [cardPinConfirmation, setCardPinConfirmation] = useState("");
  const [pinError, setPinError] = useState("");
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [pinsuccess, setpinsuccess] = useState(false);

  const validateCardPin = (value) => {
    const regex = /^\d{4}$/;
    if (regex.test(value)) {
      setPinError(""); // Clear error message
      setCardPin(value); // Save card pin if valid
    } else {
      setPinError("Pin must be exactly 4 digits.");
    }
  };

  const handleCardPinChange = (e) => {
    validateCardPin(e.target.value);
  };

  const handlesetpin = () => {
    setIsLoading(true);
    console.log("handlesetpin", cardPin);
    setcardpin();
  };

  const setcardpin = async () => {
    const response = await axios.post(
      "https://dashboard.croesus-asset.management/api/physical-cards/set-pin",
      {
        card_number: localUser.cards.cardKitNumber,
        PIN: cardPin,
        // card_number: localUser.cards.cardKitNumber,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization:
            "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
        },
      }
    );

    if (!response.status === 200) {
      setIsLoading(false);
      throw new Error("Network response was not ok");
    }
    console.log("setcardpin", response.data.message);
    if (response.data.message === "Pin changed") {
      const data = {
        userId: localUser.id,
      };

      fetch("https://api.bridge-bit.com/api/update_initial_pin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .finally(() => {
          console.log("setpin successful DB");
          setpinsuccess(true);
          onPinChanged();
        });
    }
  };

  return (
    <section>
      <div className="row">
        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label text-black">Set PIN*</label>
            <input
              type="password"
              name="pin"
              className="form-control"
              placeholder="4 Digits"
              onChange={handleCardPinChange}
              required
            />
            {pinError && <div className="text-danger">{pinError}</div>}
          </div>
        </div>
        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label text-black">Confirm PIN*</label>
            <input
              type="password"
              name="pinconfirmation"
              className="form-control"
              placeholder="4 Digits"
              required
            />
          </div>
        </div>

        {pinsuccess && (
          <div
            className="col-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="alert alert-success" role="alert">
              PIN set successfully
            </div>
          </div>
        )}
        <div
          className="col-lg-12"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <button
            type="submit"
            className="btn btn-primary "
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "100px",
            }}
            onClick={handlesetpin}
          >
            {isLoading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
              </>
            ) : (
              <div>Submit PIN</div>
            )}
          </button>
        </div>
      </div>
    </section>
  );
};

export default StepOne;
