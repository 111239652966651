import { Fragment, useState, useContext, useEffect } from "react";
import {
  useContractRead,
  useContractWrite,
  useAccount,
  useNetwork,
  useWaitForTransaction,
} from "wagmi";
import { usdt_abi } from "../../../../src/contracts/USDT_ABI.js";
import { BSC_SPLIT_ABI } from "../../../../src/contracts/BSC_SPLIT_ABI.js";
import { BSC_SPLIT_TOPUP_ABI } from "../../../../src/contracts/BSC_SPLIT_TOPUP_ABI.js";
import Web3 from "web3";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";

const BSC_USDT_CONTRACT = "0x55d398326f99059fF775485246999027B3197955";
const BSC_SPLIT_CONTRACT = "0xB124D8a36C5D657eF0801Ff13fBbe7525cebc897";

const ARBITRUM_SPLIT_CONTRACT = "0xE5fd5164eCcA1280511D4740533053d4aA5751ED";
const ARBITRUM_USDT_CONTRACT = "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9";

const POLYGON_SPLIT_CONTRACT = "0xE5fd5164eCcA1280511D4740533053d4aA5751ED";
const POLYGON_USDT_CONTRACT = "0xc2132d05d31c914a87c6611c10748aeb04b58e8f";

const BSC_TOPUP_CONTRACT = "0x42ffD035B5690e0cE8943238124009688FAC8fd2";
const BB_10_WALLET = "0x884381f19A5B2BFB604AEd8a4251Be2f7f76591f";
const CR_10_WALLET = "0x593f27504F2bE394C648F2216319Cb7fC9cf37bE";
const BB_80_WALLET = "0x0bC0A66E75FF4FaE3e449912d0b98129C81a6803";

const BuyButton = ({ Price, cardID, onError, onSuccessBuy }) => {
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [isLoading, setisLoading] = useState(false);
  const { address, isConnecting, isDisconnected } = useAccount();
  const { chain } = useNetwork();
  const [chain_id, setchain_id] = useState(chain.id);
  const [usdtBalance, setusdtBalance] = useState(0);
  const [bscAllowance, setbscAllowance] = useState(0);
  const [isapproved, setisapproved] = useState(false);
  const BigNumber = require("bignumber.js");

  let chainid = chain.id;
  let usdt_contract;
  let split_contract;
  let approve_amount;
  let buy_amount;
  if (chainid === 137) {
    usdt_contract = POLYGON_USDT_CONTRACT;
    split_contract = POLYGON_SPLIT_CONTRACT;

    let weiAmount = Price * Math.pow(10, 18);
    let maticAmount = weiAmount / Math.pow(10, 12);
    approve_amount = maticAmount;
    buy_amount = maticAmount;

    console.log("buy_amount", buy_amount);
    console.log("approve_amount", approve_amount);
  } else if (chainid === 56) {
    usdt_contract = BSC_USDT_CONTRACT;
    split_contract = BSC_SPLIT_CONTRACT;
    approve_amount = Web3.utils.toWei(Price, "ether");
    buy_amount = Web3.utils.toWei(Price, "ether");
    console.log("approve_amount", approve_amount);
  } else if (chainid === 42161) {
    usdt_contract = ARBITRUM_USDT_CONTRACT;
    split_contract = ARBITRUM_SPLIT_CONTRACT;
    approve_amount = Web3.utils.toWei(Price, "ether");
  }

  const { data: readData, isLoading: readLoading } = useContractRead({
    address: usdt_contract,
    abi: usdt_abi,
    functionName: "balanceOf",
    args: [address],
  });

  useEffect(() => {
    console.log("Network changed");
    if (chain_id != chain.id) {
      window.location.reload();
    }
  }, [chain]);

  useEffect(() => {
    console.log("balanceOf", readData);
    if (chain.id === 56) {
      setusdtBalance(Web3.utils.fromWei(String(readData), "ether"));
    } else {
      const decimals = BigNumber(6);
      let balance = BigNumber(readData) / BigNumber(10 ** Number(decimals));
      setusdtBalance(balance.toString());
    }
  }, [readData]);

  const handlesuccessbuy = () => {
    console.log("handlesuccessbuy");
    axios
      .post("https://api.bridge-bit.com/api/successbuy", {
        user: localUser.id,
        cardID: cardID,
        coin: chainid,
        // address: address,
      })
      .then((response) => {
        console.log(response);
        onSuccessBuy(cardID);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //ALLOWANCE
  // READ ALLOWANCE
  const { data: readDataAllowance, isLoading: readLoadingAllowance } =
    useContractRead({
      address: usdt_contract,
      abi: usdt_abi,
      functionName: "allowance",
      args: [address, split_contract],
    });
  console.log("allowance", readDataAllowance);

  useEffect(() => {
    let bscAllowanceETH;
    if (chainid === 56) {
      bscAllowanceETH = readDataAllowance;
      //  bscAllowanceETH = Web3.utils.fromWei(
      //     String(readDataAllowance),
      //     "ether"
      //   );
    } else {
      bscAllowanceETH = readDataAllowance;
    }
    console.log("bscAllowanceETH", bscAllowanceETH);
    setbscAllowance(bscAllowanceETH);
  }, [readDataAllowance, bscAllowance]);

  //WRITE ALLOWANCE
  const {
    data: txhashapprove,
    write: writeAllowance,
    isLoading: writeLoading,
  } = useContractWrite({
    // mode: "recklesslyUnprepared",
    address: usdt_contract,
    abi: usdt_abi,
    functionName: "approve",
    args: [split_contract, approve_amount],
    onSuccess(data) {
      console.log("approve success");
    },
    onError(error) {
      console.log("Error", error);
      setisLoading(false);
    },
  });
  const {
    data: approvedataceipt,
    isLoading: approvedataPending,
    isSuccess: approvedataSuccess,
  } = useWaitForTransaction({
    hash: txhashapprove?.hash,
    onSuccess(data) {
      console.log("onSuccess approve", data);
      setbscAllowance(approve_amount);
      setisapproved(true);
    },
    onerror(error) {
      console.log("onError", error);
      isLoading(false);
    },
  });

  //BUY FUNCTION
  const {
    data: buydata,
    write: buywrite,
    isLoading: buyisPending,
  } = useContractWrite({
    address: split_contract,
    abi: BSC_SPLIT_ABI,
    functionName: "buy",
    args: [1, buy_amount],
    onSuccess(data) {},
    onError(error) {
      console.log("Error", error);
      setisLoading(false);
    },
  });
  const {
    data: buydataceipt,
    isLoading: buydataPending,
    isSuccess: buydataSuccess,
  } = useWaitForTransaction({
    hash: buydata?.hash,
    onSuccess(data) {
      console.log("onSuccess buy", data);
      handlesuccessbuy();
    },
    onerror(error) {
      console.log("onError buy", error);
      isLoading(false);
    },
  });

  const handlebuybutton = async () => {
    console.log("handlebuybutton", Price);
    console.log("usdtBalance", usdtBalance);
    console.log("cardID", cardID);
    setisLoading(true);
    if (usdtBalance < Price) {
      console.log("USDT Balance not enough");
      onError("USDT Balance not enough");
      setisLoading(false);
    } else {
      console.log("Allowance", bscAllowance);
      console.log("Price", Price);
      if (bscAllowance < buy_amount) {
        console.log("Not enough Allowance");
        await writeAllowance();
      } else {
        setisapproved(true);
      }
    }
  };

  useEffect(() => {
    console.log("isapproved", isapproved);
    console.log("allowance", bscAllowance);
    if (isapproved && !buyisPending) {
      console.log("buywrite");
      buywrite();
    }
  }, [isapproved]);

  return (
    <button
      className="btn btn-primary mt-3"
      onClick={handlebuybutton}
      disabled={isLoading || localUser?.isKycAccepted === "false"}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: "100px",
        margin: "0px auto",
      }}
    >
      {isLoading ? (
        <Spinner
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          className="me-2"
        ></Spinner>
      ) : (
        <>{localUser?.isKycAccepted === "false" ? "KYC required" : "BUY"}</>
      )}
    </button>
  );
};
export default BuyButton;
