import React from "react";
import { useLocation } from "react-router-dom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

function VerificationResultPage() {
  console.log("VerificationResultPage");
  let navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const successMessage = queryParams.get("success");
    const errorMessage = queryParams.get("error");
    if (successMessage) {
      setMessage(successMessage);
      setSeverity("success");
    } else if (errorMessage) {
      setMessage(errorMessage);
      setSeverity("error");
    }
  }, [location]);

  const handleClick = () => {
    navigate("/login");
  };

  return (
    <Container
      component="main"
      maxWidth="sm"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Typography component="h1" variant="h5" align="center" gutterBottom>
        E-Mail Verification
      </Typography>
      {message && <Alert severity={severity}>{message}</Alert>}
      <Button
        className="mt-3"
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        style={{
          color: "white",
          background: "black",
          borderRadius: "45px",
          maxWidth: "150px",
          WebkitFontSmoothing: "antialiased",
          fontFamily: "var(--wui-font-family)",
          fontStyle: "normal",
          textRendering: "optimizespeed",
          fontWeight: "bold",
          fontSize: "1rem",
          padding: "0.5rem 0.75rem",
        }}
        onClick={handleClick}
      >
        Login
      </Button>
    </Container>
  );
}

export default VerificationResultPage;
